import { useEffect, useState } from 'react';
import { Grid, Chip, Switch, Paper } from '@mui/material';

// Redux wrappers
import CardFormated from '../../redux/containers/home/CardFormated';

//My components
import FloatingButton from './FloatingButton';
import ChangelogDialog from './ChangelogDialog';
const {
  REACT_APP_LOGING_MODE,
  REACT_APP_LOGIN_URL_EXTERNAL,
  REACT_APP_LOGIN_URL_INTERNAL,
  REACT_APP_BASE_URL_INTERNAL,
  REACT_APP_BASE_URL_EXTERNAL,
} = process.env;

//get every project to generate the cards

export default function Home(props) {
  const {
    setSession,
    loadUserRoles,
    hasProfile,
    isLoadingProfile,
    getProfile,
    getProfileData,
    hasSession,
    logOut,
    showMyProjects,
    hideFinishedProjects,
    setHideFinishedProjects,
    setShowMyProjects,
  } = props;
  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const myProjects = showMyProjects!==undefined
    ? showMyProjects
    : true;
  const hideFinished = hideFinishedProjects!==undefined
    ? hideFinishedProjects
    : true;
  const admin = props.getRole.admin;

  useEffect(() => {
    if (!hasProfile && !isLoadingProfile) {
      getProfile();
    }
  }, []);


  const handleLogOut = async () => {
    await logOut();
    if (REACT_APP_LOGING_MODE === 'DEV') {
      window.location.replace(
        REACT_APP_LOGIN_URL_INTERNAL +
          '/int?redirect=' +
          REACT_APP_BASE_URL_INTERNAL,
      );
    } else {
      window.location.replace(
        REACT_APP_LOGIN_URL_EXTERNAL +
          '/int?redirect=' +
          REACT_APP_BASE_URL_EXTERNAL,
      );
    }
  };

  const handleHideFinished = () => {
    setHideFinishedProjects(!hideFinished);
  };

  const handleMyProjects = () => {
    setShowMyProjects(!myProjects);
  };

  const handleSelectedMember = member => {
    if (!selectedMember.includes(member)) {
      setSelectedMember([...selectedMember, member]);
    }
  };

  const handleSelectedTag = tag => {
    if (!selectedTag.includes(tag)) {
      setSelectedTag([...selectedTag, tag]);
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleClearMember = member => {
    const newList = selectedMember.filter(function (e) {
      return e !== member;
    });
    setSelectedMember(newList);
  };

  const handleClearTag = tag => {
    const newList = selectedTag.filter(function (e) {
      return e !== tag;
    });
    setSelectedTag(newList);
  };

  const loadUser = () => {
    setSession(true);
    const { email, session_token} = getProfileData;
    loadUserRoles({
      email,
      session_token
    });
  };
  if (
    hasProfile
  ) {
    const email = getProfileData.email;
    if (!hasSession) {
      loadUser();
    }
    return (
      <>
      <Paper>
        <Grid
          container
          justifyContent="left"
          alignItems="left"
          spacing={{ xs: 1, md: 1 }}
          paddingTop="1em">
            
          <Grid item xs={12} style={{ paddingLeft: '2em', marginBottom: '1em'}}>
            {admin!==undefined? (
              <>
                <Switch
                  defaultChecked
                  checked={hideFinished}
                  onChange={handleHideFinished}
                />
                Hide finished projects
              </>
            ) : null}
            {admin !==undefined ? (
              <>
                <Switch
                  defaultChecked={admin === true ? false : true}
                  checked={admin === true ? !myProjects : myProjects}
                  onChange={handleMyProjects}
                />
                Show only my projects
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: '2em', marginBottom: '1em'}}>
            {selectedMember.length > 0 ? (
              <>
                Selected members:&nbsp;
                {selectedMember.map(member => (
                  <Chip
                    label={member}
                    variant="outlined"
                    onDelete={() => handleClearMember(member)}
                  />
                ))}
                <br />
                <br />
              </>
            ) : null}

            {selectedTag.length > 0 ? (
              <>
                Selected tags:&nbsp;
                {selectedTag.map(tag => (
                  <Chip
                    label={tag}
                    variant="outlined"
                    onDelete={() => handleClearTag(tag)}
                  />
                ))}
              </>
            ) : null}
          </Grid>
          </Grid>
          </Paper>
          <Grid
          container
          justifyContent="left"
          alignItems="left"
          spacing={{ xs: 1, md: 1 }}
          paddingTop="1em">
          <Grid item xs={12}>
            <CardFormated
              email={email}
              selectedMember={selectedMember}
              selectedTag={selectedTag}
              handleSelectedMember={handleSelectedMember}
              handleSelectedTag={handleSelectedTag}
              myProjects={myProjects}
              hideFinished={hideFinished}
              admin={admin}
            />
          </Grid>
        </Grid>
        <FloatingButton email={email} />
        <ChangelogDialog
          open={localStorage.getItem('update-v0.4.0') !== 'true'}
          closeDialog={handleClose}
        />
      </>
    );
  }
}
