export function snack({ snack: { snack } }) {
  return snack;
}

export function userInfo({ session: { userInfo } }) {
  return userInfo;
}

export function userInfoError({ session: {errorMessage} }) {
  return errorMessage;
}

export function session({ session: { session } }) {
  return session;
}

export function isLoadingSession({ session: { loading } }) {
  return loading;
}

export function userPolicies({ session: { userPolicies } }) {
  return userPolicies;
}

export function logs({ logs: { logs } }) {
  return logs;
}

export function payloadLogs({ logs: { payload } }) {
  return payload;
}

export function isPostingProjects({ projects: { posting_projects } }) {
  return posting_projects;
}

export function isLoadingProjects({ projects: { loading_projects } }) {
  return loading_projects;
}

export function hasProjects({ projects: { projects } }) {
  return projects;
}

export function getProjectList({ projects: { projectList } }) {
  return projectList;
}

export function getProject({ projects: { project } }) {
  return project;
}

export function hasUsers({ users: { users } }) {
  return users;
}

export function getUserList({ users: { userList } }) {
  return userList;
}

export function hasTags({ tags: { tags } }) {
  return tags;
}

export function getTagList({ tags: { tagList } }) {
  return tagList;
}

export function getRoles({ roles: { roles } }) {
  return roles;
}

export function getRoleList({ roles: { roleList } }) {
  return roleList;
}

export function isLoadingScopes({ scopes: { loading_scopes } }) {
  return loading_scopes;
}

export function hasScopes({ scopes: { scopes } }) {
  return scopes;
}

export function getScopeList({ scopes: { scopeList } }) {
  return scopeList;
}

export function getCookie({ cookies: { cookie } }) {
  return cookie;
}

export function isVerifying({ cookies: { loadingVerify } }) {
  return loadingVerify;
}

export function isVerified({ cookies: { verifyState } }) {
  return verifyState;
}

export function getProfileData({ users: { profile } }) {
  return profile;
}

export function isLoadingProfile({ users: { loading_profile } }) {
  return loading_profile;
}

export function hasProfile({ users: { profileState } }) {
  return profileState;
}

export function getRole({ users: { roles } }) {
  return roles;
}

export function isLoadinRole({users: {loading_roles}}) {
  return loading_roles;
}

export function hasSession({ users: { session } }) {
  return session;
}

export function loading({ loading: { loading } }) {
  return loading;
}

export function hasRoles({ roles: { roles } }) {
  return roles;
}

export function hideFinishedProjects({ users: { hideFinished } }) {
  return hideFinished;
}

export function showMyProjects({ users: { myProjects } }) {
  return myProjects;
}

export function isLoading({
  session: { loading: sessionLoading },
  logs: { loading: logsLoading },
}) {
  return (
    logsLoading ||
    sessionLoading
  );
}
