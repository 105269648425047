import { connect } from 'react-redux';
import { getProjects, setProject } from 'core/slices/projects';
import { resetScopeList } from 'core/slices/scopes';
import { loadingUpdate } from 'core/slices/loading';
import {
  hasProjects,
  isLoadingProjects,
  getProjectList,
  getProfileData,
} from 'core/selectors';
import CardFormated from '../../../components/home/CardFormated';

export default connect(
  state => ({
    hasProjects: hasProjects(state),
    isLoadingProjects: isLoadingProjects(state),
    projects: getProjectList(state),
    getProfileData: getProfileData(state),
  }),
  {
    getProjects,
    resetScopeList,
    setProject,
    loadingUpdate,
  },
)(CardFormated);
