import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Grid,
  Divider,
  Box,
} from '@mui/material';

export default function EditTagsDialog(props) {
  const {
    closeDialog,
    open,
    project,
    tags,
    loadingUpdate,
    updateProject,
    getProjects,
  } = props;
  const [tagValues, setTagValues] = React.useState([]);

  React.useEffect(() => {
    let newTagValues = [...tagValues];
    project.tags_ids.forEach(value => {
      newTagValues.push({ tag: value, disabled: false });
    });
    if (project.tags_ids.length === 0) {
      newTagValues.push({ tag: '', disabled: false });
    }
    setTagValues(
      newTagValues.filter((v, i, a) => a.findIndex(t => t.tag === v.tag) === i),
    );
  }, []);

  const handleClose = () => {
    closeDialog();
    let newTagValues = [...tagValues];
    project.tags_ids.forEach(value => {
      newTagValues.push({ tag: value, disabled: false });
    });
    if (project.tags_ids.length === 0) {
      newTagValues.push({ tag: '', disabled: false });
    }
    setTagValues(
      newTagValues.filter((v, i, a) => a.findIndex(t => t.tag === v.tag) === i),
    );
  };

  const handleTagChange = (index, event) => {
    let newTagValues = [...tagValues];
    newTagValues[index][event.target.name] = event.target.value;
    setTagValues(newTagValues);
  };

  const addTagFields = index => {
    let newTagValues = [...tagValues];
    newTagValues[index]['disabled'] = true;
    setTagValues([...newTagValues, { tag: '', disabled: false }]);
  };

  const removeTagFields = index => {
    let newTagValues = [...tagValues];
    newTagValues.splice(index, 1);
    newTagValues[index - 1]['disabled'] = false;
    setTagValues(newTagValues);
  };

  const handleSubmit = async () => {
    loadingUpdate(true);
    handleClose();
    const tags_ids = tagValues.map(v => v.tag);
    await updateProject({
      project_id: project.id,
      tags_ids,
      to_update: 'tags',
    });
    await getProjects();
    loadingUpdate(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>Edit project details</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom="1em">
          In order to edit the project details, please modify the fields below.
        </DialogContentText>

        <Divider sx={{ margin: 1 }}>Tags</Divider>
        <Grid container spacing={2}>
          {tagValues.map((element, index) => (
            <>
              <Grid item xs={10}>
                <TextField
                  required
                  select
                  margin="dense"
                  label="Tag"
                  type="text"
                  name="tag"
                  fullWidth
                  variant="outlined"
                  value={tagValues[index].tag}
                  key={element.tag}
                  onChange={event => handleTagChange(index, event)}>
                  {tags.map(option => (
                    <MenuItem
                      disabled={tagValues.some(e => e.tag === option.value)}
                      key={option.value}
                      value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {tagValues.length <= 1 ? (
                <Grid item xs={2}>
                  <Box margin={1.4}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="success"
                      onClick={() => addTagFields(index)}>
                      <AddIcon fontSize="large" />
                    </Button>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    fullWidth
                    disabled={tagValues.length > 1 ? element.disabled : false}
                    color="success"
                    onClick={() => addTagFields(index)}>
                    <AddIcon />
                  </Button>
                  <Button
                    variant="outlined"
                    fullWidth
                    disabled={tagValues.length > 1 ? element.disabled : true}
                    color="error"
                    onClick={() => removeTagFields(index)}>
                    <RemoveIcon />
                  </Button>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          marginLeft: '1em',
          marginRight: '1em',
        }}>
        <Button onClick={handleClose}>cancel</Button>
        <Button onClick={handleSubmit} color="success">
          edit project
        </Button>
      </DialogActions>
    </Dialog>
  );
}
