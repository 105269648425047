import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { Routes, Route, HashRouter as Router } from 'react-router-dom';
import '../App.css';

import Home from './redux/containers/home/Home';
import Layout from './redux/containers/navbar/Layout';
import Project from './redux/containers/project/Project';
import AdminView from './redux/containers/user/AdminView';
import EntryPoint from './redux/containers/providers/Entrypoint';
import Login from './components/login/Login';
// custom views
import Logs from './redux/containers/logs/Logs';

// instantiate services and wire up dependencies
import createStore from 'core/store';
import HTTPService from 'core/services/http';
import DataSource from 'core/services/dataSource';

const { REACT_APP_API_BASE_URL } = process.env;

const http = new HTTPService();

const dataSource = new DataSource({
  baseUrl: REACT_APP_API_BASE_URL,
  http,
});

const store = createStore({
  dataSource,
});

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <EntryPoint>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Project" element={<Project />} />
              <Route path="/AdminView" element={<AdminView />} />
              <Route path="/Logs" element={<Logs />} />
            </Routes>
          </Layout>
        </EntryPoint>
      </Router>
    </Provider>
  );
};

export default App;
