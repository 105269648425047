import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import StyledModal from '../../tools/StyledModal';
import { JSONTree } from 'react-json-tree';

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

export default function PayloadModal(props) {
  const { open, handleClose, onConfirmationComplete, title, payload } = props;

  const overrideStyles = {
    position: 'absolute',
    maxWidth: { xs: '90%', md: '90%' },
    borderRadius: '0.5rem',
    boxShadow: 24,
    padding: '2rem',
    maxHeight: '80vh',
    overflowY: 'auto',
    margin: '2rem',
    backgroundColor: 'white',
  };

  return (
    <StyledModal
      open={open}
      handleClose={handleClose}
      title={title}
      disableCancelation={true}
      overrideStyles={overrideStyles}
      onConfirmationComplete={onConfirmationComplete}>
      <Grid container justifyContent="left" alignItems="left" spacing={2}>
        <Grid item xs={12} align="left">
          <JSONTree
            data={payload}
            theme={theme}
            invertTheme={true}
            valueRenderer={raw => <em>{raw}</em>}
            expandActionRoot="true"
            labelRenderer={([key]) => (
              <em align="left" padding-left="10%">
                {key}
              </em>
            )}
          />
        </Grid>
      </Grid>
    </StyledModal>
  );
}
