import { connect } from 'react-redux';
import SideMenu from '../../../components/navbar/SideMenu';
import { userPolicies, getProfileData } from 'core/selectors';

export default connect(
  state => ({
    userPolicies: userPolicies(state),
    getProfileData: getProfileData(state),
  }),
  {},
)(SideMenu);
