import { connect } from 'react-redux';
import { loadUserRoles} from 'core/slices/users';
import { getRole } from 'core/selectors';
import Login from '../../../components/login/Login';

export default connect(
  state => ({
    getRole: getRole(state),
  }),
  { loadUserRoles },
)(Login);
