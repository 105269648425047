import { useState } from 'react';
import { Grid, Typography, Box, Button, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import EditOffIcon from '@mui/icons-material/EditOff';

//my components
import DeleteDialog from '../DeleteDialog';

export default function UserComponent(props) {
  const [editMode, setEditMode] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { user, getUsers, updateUser, deleteUser, loadingUpdate, getProjects } =
    props;
  const { first_name, last_name, email, value, label, avatar } = user;
  const [formValues, setFormValues] = useState({
    first_name,
    last_name,
    username: label,
    email,
    user_id: value,
    avatar
  });

  const handleDelete = async () => {
    setOpenDeleteDialog(true);
  };
  const handleEditMode = () => {
    setFormValues({
      first_name: first_name,
      last_name: last_name,
      username: label,
      email: email,
      user_id: value,
      avatar,
    });
    setEditMode(!editMode);
  };
  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };
  const handleUpdateUser = async () => {
    const newForm = { ...formValues, user_id: value };
    loadingUpdate(true);
    await updateUser(newForm);
    await getUsers();
    await getProjects();
    setFormValues({
      first_name: first_name,
      last_name: last_name,
      username: label,
      email: email,
      user_id: value,
      avatar,
    });
    loadingUpdate(false);
    handleEditMode();
  };

  if (!editMode) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <Typography variant="subtitle1" align="center" marginTop={3}>
              {user.first_name}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1" align="center" marginTop={3}>
              {user.last_name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1" align="center" marginTop={3}>
              {user.label}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1" align="center" marginTop={3}>
              {user.email}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" align="center" marginTop={3}>
              {user.avatar.substring(0, 30)}...
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Box marginTop={'1em'}>
              <Button variant="outlined" onClick={handleEditMode}>
                <EditIcon />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box marginTop={'1em'}>
              <Button variant="outlined" onClick={handleDelete}>
                <CloseIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
        <DeleteDialog
          open={openDeleteDialog}
          toDelete={user.value}
          toDeleteName={'user'}
          deleteFunction={deleteUser}
          getFunction={getUsers}
          loadingUpdate={loadingUpdate}
          closeDialog={setOpenDeleteDialog}
          getSecondary={getProjects}
          goHome={false}
        />
      </>
    );
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <TextField
            margin="dense"
            name="first_name"
            value={formValues.first_name}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            margin="dense"
            name="last_name"
            value={formValues.last_name}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            margin="dense"
            name="username"
            value={formValues.username}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            margin="dense"
            name="email"
            value={formValues.email}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            margin="dense"
            name="avatar"
            value={formValues.avatar}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <Box marginTop={'1em'}>
            <Button variant="outlined" onClick={handleUpdateUser}>
              <SaveIcon />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box marginTop={'1em'}>
            <Button variant="outlined" onClick={handleEditMode}>
              <EditOffIcon />
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
