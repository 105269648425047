import { connect } from 'react-redux';
import { getProfile, loadUserRoles, setSession, logOut, setHideFinishedProjects, setShowMyProjects } from 'core/slices/users';
import {
  getProfileData,
  hasProfile,
  isLoadingProfile,
  getRole,
  hasSession,
  showMyProjects,
  hideFinishedProjects,
} from 'core/selectors';
import Home from '../../../components/home/Home';

export default connect(
  state => ({
    getProfileData: getProfileData(state),
    hasProfile: hasProfile(state),
    isLoadingProfile: isLoadingProfile(state),
    getRole: getRole(state),
    hasSession: hasSession(state),
    showMyProjects: showMyProjects(state),
    hideFinishedProjects: hideFinishedProjects(state),
  }),
  { getProfile, loadUserRoles, setSession, logOut, setHideFinishedProjects, setShowMyProjects },
)(Home);
