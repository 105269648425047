import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Grid,
} from '@mui/material';

export default function EditScopeDialog(props) {
  const {
    closeDialog,
    open,
    scope,
    loadingUpdate,
    updateScopes,
    getProjects,
    getScopes,
    project_id,
  } = props;
  const [formValues, setFormValues] = React.useState({
    scope_name: scope.name,
    description: scope.description,
    color_hex: scope.color,
  });

  const handleClose = () => {
    closeDialog();
    setFormValues({
      scope_name: scope.name,
      description: scope.description,
      color_hex: scope.color,
    });
  };

  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    loadingUpdate(true);
    handleClose();
    await updateScopes({
      ...formValues,
      scope_id: scope.id,
      to_update: 'edit',
    });
    await getScopes(project_id);
    await getProjects();
    loadingUpdate(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>Edit scope</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom="1em">
          In order to edit the scope, please modify the fields below.
        </DialogContentText>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              autoFocus
              margin="dense"
              label="Scope name"
              name="scope_name"
              defaultValue={formValues.scope_name}
              type="text"
              fullWidth
              variant="outlined"
              onChange={event => handleChange(event)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              multiline
              label="Scope description"
              name="description"
              type="number"
              margin="dense"
              defaultValue={formValues.description}
              fullWidth
              variant="outlined"
              onChange={handleChange}></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Scope color"
              name="color_hex"
              type="color"
              margin="dense"
              defaultValue={formValues.color_hex}
              fullWidth
              variant="outlined"
              onChange={handleChange}></TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          marginLeft: '1em',
          marginRight: '1em',
        }}>
        <Button onClick={handleClose}>cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          edit scope
        </Button>
      </DialogActions>
    </Dialog>
  );
}
