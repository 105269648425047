import { createReducer } from './utils.js';
import {
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAILURE,
  SNACK_SET,
} from './constants.js';
import { userInfo } from '../selectors.js';
const defaultState = {
  loading: false,
  errorMessage: null,
  userInfo: null,
  userPolicies: null,
};

export const reducer = createReducer(defaultState, {
  [GET_SESSION_REQUEST]: handleGetSessionRequest,
  [GET_SESSION_SUCCESS]: handleGetSessionSuccess,
  [GET_SESSION_FAILURE]: handleGetSessionFailure,
});

function handleGetSessionRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function handleGetSessionSuccess(state, { payload: { userInfo } }) {
  return {
    ...state,
    userInfo: userInfo?.currentUser,
    userPolicies: userInfo?.auth?.grantedPolicies,
    loading: false,
    errorMessage: null,
  };
}

function handleGetSessionFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
    userInfo: null,
    userPolicies: null,
  };
}

export function getUserInfo() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SESSION_REQUEST });
    try {
      //const userInfo = await dataSource.getUserInfo();
      dispatch({ type: GET_SESSION_SUCCESS, payload: { userInfo } });
    } catch (error) {
      console.log('error', error);
      dispatch({ type: GET_SESSION_FAILURE, payload: { error } });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error al cargar sesión: ' + error,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
