export const CREDENTIALS_REQUEST = 'CREDENTIALS_REQUEST';
export const CREDENTIALS_AVAILABLE = 'CREDENTIALS_AVAILABLE';
export const CREDENTIALS_UNAVAILABLE = 'CREDENTIALS_UNAVAILABLE';
export const CREDENTIALS_STORE = 'CREDENTIALS_STORE';
export const CREDENTIALS_CLEAR = 'CREDENTIALS_CLEAR';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const PROJECT_REQUEST = 'PROJECT_REQUEST';
export const PROJECT_AVAILABLE = 'PROJECT_AVAILABLE';
export const PROJECT_UNAVAILABLE = 'PROJECT_UNAVAILABLE';
export const PROJECT_POST_FAILED = 'PROJECT_POST_FAILED';
export const PROJECT_POST = 'PROJECT_POST';
export const PROJECT_POSTED = 'PROJECT_POSTED';
export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_DELETED = 'PROJECT_DELETED';
export const PROJECT_DELETE_FAILED = 'PROJECT_DELETE_FAILED';
export const PROJECT_SET = 'PROJECT_SET';

export const USERS_AVAILABLE = 'USERS_AVAILABLE';
export const USERS_UNAVAILABLE = 'USERS_UNAVAILABLE';
export const USERS_USERNAME_AVAILABLE = 'USERS_USERNAME_AVAILABLE';
export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_AVAILABLE = 'USER_PROFILE_AVAILABLE';
export const USER_PROFILE_UNAVAILABLE = 'USER_PROFILE_UNAVAILABLE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SESSION_SET = 'USER_SESSION_SET';
export const USER_ROLES_REQUEST = 'USER_ROLES_REQUEST';
export const USER_ROLES_AVAILABLE = 'USER_ROLES_AVAILABLE';
export const USER_ROLES_UNAVAILABLE = 'USER_ROLES_UNAVAILABLE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';
export const USER_DELETE = 'USER_DELETE';
export const USER_DELETED = 'USER_DELETED';
export const USER_DELETE_FAILED = 'USER_DELETE_FAILED';
export const USER_MY_PROJECTS = 'USER_MY_PROJECTS';
export const USER_HIDE_FINISHED = 'USER_HIDE_FINISHED';

export const ROLES_AVAILABLE = 'ROLES_AVAILABLE';
export const ROLES_UNAVAILABLE = 'ROLES_UNAVAILABLE';

export const TAGS_AVAILABLE = 'TAGS_AVAILABLE';
export const TAGS_UNAVAILABLE = 'TAGS_UNAVAILABLE';
export const TAGS_POST = 'TAGS_POST';
export const TAGS_POSTED = 'TAGS_POSTED';
export const TAGS_POST_FAILED = 'TAGS_POST_FAILED';
export const TAGS_DELETE = 'TAGS_DELETE';
export const TAGS_DELETED = 'TAGS_DELETED';
export const TAGS_DELETE_FAILED = 'TAGS_DELETE_FAILED';

export const SCOPE_POST = 'SCOPE_POST';
export const SCOPE_POSTED = 'SCOPE_POSTED';
export const SCOPE_POST_FAILED = 'SCOPE_POST_FAILED';
export const SCOPE_REQUEST = 'SCOPE_REQUEST';
export const SCOPE_AVAILABLE = 'SCOPE_AVAILABLE';
export const SCOPE_UNAVAILABLE = 'SCOPE_UNAVAILABLE';
export const SCOPE_DELETE = 'SCOPE_DELETE';
export const SCOPE_DELETED = 'SCOPE_DELETED';
export const SCOPE_DELETE_FAILED = 'SCOPE_DELETE_FAILED';
export const SCOPE_UPDATE = 'SCOPE_UPDATE';
export const SCOPE_UPDATED = 'SCOPE_UPDATED';
export const SCOPE_UPDATE_FAILED = 'SCOPE_UPDATE_FAILED';

export const SNAPSHOTS_POST = 'SNAPSHOTS_POST';
export const SNAPSHOTS_POSTED = 'SNAPSHOTS_POSTED';
export const SNAPSHOTS_POST_FAILED = 'SNAPSHOTS_POST_FAILED';

export const TASKS_POST = 'TASKS_POST';
export const TASKS_POSTED = 'TASKS_POSTED';
export const TASKS_POST_FAILED = 'TASKS_POST_FAILED';
export const TASKS_DELETE = 'TASKS_DELETE';
export const TASKS_DELETED = 'TASKS_DELETED';
export const TASKS_DELETE_FAILED = 'TASKS_DELETE_FAILED';

export const COOKIE_VERIFY_REQUEST = 'COOKIE_VERIFY_REQUEST';
export const COOKIE_VERIFY_AVAILABLE = 'COOKIE_VERIFY_AVAILABLE';
export const COOKIE_VERIFY_UNAVAILABLE = 'COOKIE_VERIFY_UNAVAILABLE';

export const LOADING_UPDATE = 'LOADING_UPDATE';

export const SNACK_SET = 'SNACK_SET';
export const GET_SESSION_REQUEST = 'GET_SESSION_REQUEST';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAILURE = 'GET_SESSION_FAILURE';
export const GET_LOGS_FAILURE = 'GET_LOGS_FAILURE';
export const GET_LOGS_REQUEST = 'GET_LOGS_REQUEST';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOG_PAYLOAD_FAILURE = 'GET_LOG_PAYLOAD_FAILURE';
export const GET_LOG_PAYLOAD_REQUEST = 'GET_LOG_PAYLOAD_REQUEST';
export const GET_LOG_PAYLOAD_SUCCESS = 'GET_LOG_PAYLOAD_SUCCESS';