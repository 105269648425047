import { useState } from 'react';

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from '@myclevergroup/react-grid-dnd-but-better';

import Scope from '../../redux/containers/project/Scope';

export default function DragabbleCard(props) {
  const [scopes, setScopes] = useState([...props.scopes]);
  const {
    project,
    username,
    users,
    role,
    updateScopes,
    loadingUpdate,
    getScopes,
    height,
  } = props;

  const handleUpdate = async scopes => {
    loadingUpdate(true);
    await updateScopes({ scopes, to_update: 'list' });
    await getScopes(project.id);
    loadingUpdate(false);
  };

  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    const nextState = swap(scopes, sourceIndex, targetIndex);
    const newScopes = nextState.map((element, index) => {
      return [element.id, index];
    });
    handleUpdate(newScopes.flat());
    setScopes(nextState);
  };

  return (
    <div data-scroll>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          className="dropzone down"
          id="done"
          boxesPerRow={3}
          rowHeight={150}
          style={{ height: `${height * Math.ceil(scopes.length / 3)}px` }}>
          {scopes.map((scope, index) => (
            <GridItem key={scope.id}>
              <Scope
                scope={scope}
                project={project}
                created_by={username}
                users={users}
                role={role}
                isDragging={true}
              />
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
    </div>
  );
}
