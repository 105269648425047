export default function DataSourceService({ baseUrl, http }) {
  const PATH_VERIFY = '/verify';
  const PATH_SIGN_IN = '/auth';
  const PATH_PROJECTS = '/projects';
  const PATH_ROLES = '/roles';
  const PATH_TAGS = '/tags';
  const PATH_USERS = '/users';
  const PATH_SCOPES = '/scopes';
  const PATH_SNAPSHOTS = '/snapshots';
  const PATH_TASKS = '/tasks';
  const PATH_PROFILE = '/profile';
  const PATH_LOGOUT = '/sessionLogout';

  var AUTH_URL = '';

  return {
    getAccessToken,
    getProjects,
    getUsers,
    getRoles,
    getTags,
    getScopes,
    postProject,
    postScope,
    postSnapshots,
    postTasks,
    deleteProject,
    updateTask,
    deleteScope,
    verifyCookie,
    getProfile,
    logOut,
    loadUserRoles,
    updateScopes,
    deleteTask,
    updateProject,
    postTags,
    updateTags,
    deleteTags,
    updateUser,
    deleteUser,
  };

  function getAccessToken({ phone, password }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SIGN_IN}`,
      body: {
        phone,
        password,
      },
    });
  }

  function getProjects() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_PROJECTS}`,
    });
  }

  function postProject(project) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_PROJECTS}`,
      body: project,
    });
  }

  function deleteProject(projectId) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_PROJECTS}?project_id=${projectId}`,
    });
  }

  function updateProject(project) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_PROJECTS}`,
      body: project,
    });
  }

  function getUsers() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USERS}`,
    });
  }

  function getRoles() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROLES}`,
    });
  }

  function getTags() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TAGS}`,
    });
  }

  function getScopes(project_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SCOPES}?project_id=${project_id}`,
    });
  }

  function postScope(scope) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SCOPES}`,
      body: scope,
    });
  }

  function postSnapshots(snapshots) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SNAPSHOTS}`,
      body: snapshots,
    });
  }

  function postTasks(tasks) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_TASKS}`,
      body: tasks,
    });
  }

  function updateTask(task) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_TASKS}`,
      body: task,
    });
  }

  function deleteScope(ScopeId) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_SCOPES}?scope_id=${ScopeId}`,
    });
  }

  function verifyCookie() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_PROFILE}`,
      credentials: 'include',
    });
  }

  function getProfile(body) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_PROFILE}`,
      credentials: 'include',
    });
  }

  function logOut() {
    return http.getAuthenticated({
      url: `${AUTH_URL}${PATH_LOGOUT}`,
      credentials: 'include',
    });
  }

  function loadUserRoles(profile) {
    return http.postAuthenticated({
      credentials: 'include',
      url: `${baseUrl}${PATH_USERS}`,
      body: profile,
    });
  }

  function updateScopes(scopes) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_SCOPES}`,
      body: scopes,
    });
  }

  function deleteTask(taskId) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_TASKS}?task_id=${taskId}`,
    });
  }

  function postTags(tags) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_TAGS}`,
      body: tags,
    });
  }

  function updateTags(tags) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_TAGS}`,
      body: tags,
    });
  }

  function deleteTags(tags) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_TAGS}?tag_id=${tags}`,
    });
  }

  function updateUser(user) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_USERS}`,
      body: user,
    });
  }

  function deleteUser(userId) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_USERS}?user_id=${userId}`,
    });
  }
}
