import { createReducer } from './utils.js';
import {
  GET_LOGS_REQUEST,
  GET_LOGS_SUCCESS,
  GET_LOGS_FAILURE,
  GET_LOG_PAYLOAD_REQUEST,
  GET_LOG_PAYLOAD_SUCCESS,
  GET_LOG_PAYLOAD_FAILURE,
  SNACK_SET,
} from './constants.js';
const defaultState = {
  loading: false,
  errorMessage: null,
  logs: [],
  payload: null,
};

export const reducer = createReducer(defaultState, {
  [GET_LOGS_REQUEST]: handleGetLogsRequest,
  [GET_LOGS_SUCCESS]: handleGetLogsSuccess,
  [GET_LOGS_FAILURE]: handleGetLogsFailure,
  [GET_LOG_PAYLOAD_REQUEST]: handleGetLogPayloadRequest,
  [GET_LOG_PAYLOAD_SUCCESS]: handleGetLogPayloadSuccess,
  [GET_LOG_PAYLOAD_FAILURE]: handleGetLogPayloadFailure,
});

function handleGetLogsRequest(state) {
  return {
    ...state,
    logs: [],
    loading: true,
  };
}

function handleGetLogsSuccess(state, { payload: { logs } }) {
  return {
    ...state,
    logs,
    loading: false,
    errorMessage: null,
  };
}

function handleGetLogsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
  };
}

function handleGetLogPayloadRequest(state) {
  return {
    ...state,
    payload: null,
    loading: true,
  };
}

function handleGetLogPayloadSuccess(state, { payload }) {
  return {
    ...state,
    payload,
    loading: false,
    errorMessage: null,
  };
}

function handleGetLogPayloadFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
  };
}

export function getLogs() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_LOGS_REQUEST });
    try {
      const logs = await dataSource.getLogs();
      dispatch({
        type: GET_LOGS_SUCCESS,
        payload: {
          logs,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_LOGS_FAILURE,
        payload: {
          error: error.message,
        },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error al cargar logs: ' + error.message,
      };
        dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getLogPayload(id) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_LOG_PAYLOAD_REQUEST });
    try {
      const payload = await dataSource.getLogPayload(id);
      dispatch({
        type: GET_LOG_PAYLOAD_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_LOG_PAYLOAD_FAILURE,
        payload: {
          error: error.message,
        },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error al cargar payload: ' + error.message,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
