import { Card, CardContent } from '@mui/material';

//my components
import UserComponent from './UserComponent';

export default function UsersCards(props) {
  const { getUsers, updateUser, deleteUser, loadingUpdate, getProjects } = props;
  var users = props.getUserList;
  users.sort((a, b) => a.first_name.localeCompare(b.first_name));
  return (
    <Card>
      <CardContent>
        {/*sx={{ overflow: 'auto', height: '400px' }}*/}
        {users.map(user => (
          <UserComponent
            user={user}
            getUsers={getUsers}
            updateUser={updateUser}
            deleteUser={deleteUser}
            loadingUpdate={loadingUpdate}
            getProjects={getProjects}
          />
        ))}
      </CardContent>
    </Card>
  );
}
