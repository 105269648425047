import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  IconButton,
  Toolbar,
  Typography,
  LinearProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import logo from '../../../images/logo.png';
import { useNavigate } from 'react-router-dom';

const REACT_APP_WEB_FICHA = process.env.REACT_APP_WEB_FICHA;

const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const drawerWidth = 350;

const styles = {
  logo: {
    height: 50,
  },
};

export default function Navbar(props) {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const navigate = useNavigate();

  const { open, handleDrawer, isLoading, snack, setSnackAlert } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const newSnack = { open: false, severity: 'success', message: '' };
    setSnackAlert(newSnack);
  };
  useEffect(() => {
    if (snack.open) {
      setSnackBarOpen(true);
    } else {
      setSnackBarOpen(false);
    }
  }, [snack]);

  const location = useLocation();

  return (
    <AppBar position="fixed" open={open} sx={{ bgcolor: '#ffffff' }}>
      <Toolbar>
        {/* Menu open button */}

        <img
          style={styles.logo}
          src={logo}
          alt="logo"
          onClick={() => {
            navigate('/');
          }}
          onMouseOver={e => {
            e.currentTarget.style.cursor = 'pointer';
          }}
          onMouseLeave={e => {
            e.currentTarget.style.cursor = 'default';
          }}
        />

        {/* spacing */}
        <Typography
          align="center"
          variant="h6"
          fontSize={20}
          color={'text.primary'}
          style={{
            width: '100%',
            alignItems: 'center',
            paddingRight: 160 - 48, // 160 is the width of the logo and 48 is the width of the side menu
          }}>
          {
            location.pathname.split('/').pop() === 'logs'
            ? 'Logs'
            : 'ShapeApp'}
        </Typography>
      </Toolbar>
      {isLoading && <LinearProgress />}
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snack.severity}
          variant="filled"
          sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </AppBar>
  );
}
