import { connect } from 'react-redux';
import { getLogs, getLogPayload } from 'core/slices/logs';
import { logs, payloadLogs } from 'core/selectors';
import Logs from '../../../components/logs/Logs';

export default connect(
  state => ({
    logs: logs(state),
    payloadLogs: payloadLogs(state),
  }),
  { getLogs, getLogPayload },
)(Logs);
