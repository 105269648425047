import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import InactivityModal from './modals/inactivityModal';
import Login from '../../redux/containers/login/Login';

const EntryPoint = ({ getUserInfo, userInfo, children, userInfoError, loadUserRoles, getRoles, getProfile, getProfileData }) => {
  const [remaining, setRemaining] = useState(0);
  const [open, setOpen] = useState(false);

  const timeout = 1000 * 60 * 10;
  const promptBeforeIdle = 1000 * 60 * 1;

  const onIdle = () => {
    //window.location.href = REACT_APP_WEB_FICHA
  };

  const onActive = () => {
    setOpen(false)
  };

  const onPrompt = () => {
    setOpen(true)
  }


  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    getProfile();
  }, []);

  const handleStillHere = () => {
    activate()
  }
  const seconds = remaining > 1 ? 'segundos' : 'segundo'

  const REACT_APP_WEB_FICHA = process.env.REACT_APP_WEB_FICHA;


  if (!getProfileData?.user_id) {
    return (
      <>
        <Login />
      </>
    );
  }else{
    return (
      <>
        {children}
        <InactivityModal
          open={open}
          handleClose={ () => console.log('cerrar')}
          remaining={remaining}
          seconds={seconds}
          title="Su sesión está a punto de expirar"
          onConfirmationComplete={handleStillHere}
        />
      </>
    );
  }


  /* logica con sesión valida */

  if (userInfo) {
    if (userInfo?.id) {
      return (
        <>
          {children}
          <InactivityModal
            open={open}
            handleClose={ () => window.location.href = REACT_APP_WEB_FICHA}
            remaining={remaining}
            seconds={seconds}
            title="Su sesión está a punto de expirar"
            onConfirmationComplete={handleStillHere}
          />
        </>
      );
    } else {
      return null;
    }
  } else {
    if (userInfoError) {
      //window.location.href = REACT_APP_WEB_FICHA;
    }
    return null;
  }
  
};

export default EntryPoint;
