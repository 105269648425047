import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

export default function ChangelogDialog(props) {
  const { closeDialog, open } = props;
  const handleInfo = () => {
    window.open('https://slack.com/app_redirect?channel=U02SQ7HDX8A', '_blank');
  };
  const handleClose = () => {
    localStorage.setItem('update-v0.4.0', 'true');
    closeDialog();
  };

  const handleSubmit = async () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>Patch notes</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom="1em">
          Welcome to the new version of the Shape App.
          <br />
          Dont worry you will only see this dialog once (until a new version is
          released).
          <br />
          <br />
          <b>Whats new?</b>
          <br />
          - The app is back online!
          <br />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="success">
          Great!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
