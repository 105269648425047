import { useState } from 'react';
import {
  Paper,
  Grid,
  Divider,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  Card,
  CardActionArea,
  CardContent,
  TextField,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import EditOffIcon from '@mui/icons-material/EditOff';

//my components
import DeleteDialog from '../DeleteDialog';

export default function TagComponent(props) {
  const [editMode, setEditMode] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { tag, getTags, updateTags, deleteTags, loadingUpdate, getProjects } =
    props;
  const [formValues, setFormValues] = useState({
    tag_name: tag.label,
    tag_id: tag.value,
  });

  const handleDelete = async () => {
    setOpenDeleteDialog(true);
  };
  const handleEditMode = () => {
    setEditMode(!editMode);
    setFormValues({ tag_name: tag.label, tag_id: tag.value });
  };
  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };
  const handleUpdateTag = async () => {
    const newForm = { ...formValues, tag_id: tag.value };
    loadingUpdate(true);
    await updateTags(newForm);
    await getTags();
    await getProjects();
    setFormValues({ tag_name: tag.label, tag_id: tag.value });
    handleEditMode();
    loadingUpdate(false);
  };

  if (!editMode) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <Typography variant="subtitle1" align="center" marginTop={3}>
              {tag.label}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Box marginTop={'1em'}>
              <Button variant="outlined" onClick={handleEditMode}>
                <EditIcon />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box marginTop={'1em'}>
              <Button variant="outlined" onClick={handleDelete}>
                <CloseIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
        <DeleteDialog
          open={openDeleteDialog}
          toDelete={tag.value}
          toDeleteName={'tag'}
          deleteFunction={deleteTags}
          getFunction={getTags}
          loadingUpdate={loadingUpdate}
          closeDialog={setOpenDeleteDialog}
          getSecondary={getProjects}
          goHome={false}
        />
      </>
    );
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <TextField
            margin="dense"
            value={formValues.tag_name}
            name="tag_name"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={1}>
          <Box marginTop={'1em'}>
            <Button variant="outlined" onClick={handleUpdateTag}>
              <SaveIcon />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box marginTop={'1em'}>
            <Button variant="outlined" onClick={handleEditMode}>
              <EditOffIcon />
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
