import { connect } from 'react-redux';
import { getUserInfo } from 'core/slices/session';
import { getProfile, loadUserRoles } from 'core/slices/users';
import EntryPoint from '../../../components/providers/Entrypoint';
import { userInfo, userInfoError, getRoles, getProfileData } from 'core/selectors';

export default connect(
  state => ({
    userInfo: userInfo(state),
    getRoles: getRoles(state),
    getProfileData: getProfileData(state),
    userInfoError: userInfoError(state)
  }),
  { getUserInfo, loadUserRoles, getProfile },
)(EntryPoint);
