import qs from 'querystring';

export default function HTTPService() {
  const { REACT_APP_API_KEY } = process.env;
  const HTTP_METHOD_GET = 'GET';
  const HTTP_METHOD_POST = 'POST';
  const HTTP_METHOD_DELETE = 'DELETE';
  const HTTP_METHOD_PUT = 'PUT';
  const HTTP_METHOD_PATCH = 'PATCH';
  const HTTP_HEADER_ACCEPT = 'Accept';
  const HTTP_HEADER_CONTENT_TYPE = 'Content-Type';
  const HTTP_CREDENTIALS = 'credentials';
  const HTTP_CREDENTIALS_TYPE = 'include';
  const HTTP_API_KEY = 'x-api-key';

  const MIME_TYPE_JSON = 'application/json;charset=utf-8';

  return {
    getAuthenticated,
    postAuthenticated,
    deleteAuthenticated,
    putAuthenticated,
    patchAuthenticated,
    downloadAuthenticated,
  };

  function getAuthenticated({ url, query, access_token }) {
    return makeRequest({
      method: HTTP_METHOD_GET,
      url,
      query,
      headers: {
        Authorization: access_token,
      },
    });
  }

  function downloadAuthenticated({ url, query, access_token }) {
    return makeBlobRequest({
      method: HTTP_METHOD_GET,
      url,
      query,
      headers: {
        Authorization: access_token,
        responseType: 'blob',
      },
    });
  }

  function postAuthenticated({ url, body, access_token }) {
    return makeRequest({
      method: HTTP_METHOD_POST,
      url,
      body,
      headers: {
        Authorization: access_token,
      },
    });
  }

  function deleteAuthenticated({ url, query, body, access_token }) {
    return makeRequest({
      method: HTTP_METHOD_DELETE,
      url,
      query,
      body,
      headers: {
        Authorization: access_token,
      },
    });
  }

  function putAuthenticated({ url, body, access_token }) {
    return makeRequest({
      method: HTTP_METHOD_PUT,
      url,
      body,
      headers: {
        Authorization: access_token,
      },
    });
  }

  function patchAuthenticated({ url, body, access_token }) {
    return makeRequest({
      method: HTTP_METHOD_PATCH,
      url,
      body,
      headers: {
        Authorization: access_token,
      },
    });
  }

  async function makeRequest({
    method,
    url,
    query = {},
    body = {},
    headers = {},
  }) {
    const finalUrl =
      query && Object.keys(query).length
        ? `${url}?${qs.stringify(query)}`
        : url;

    const finalHeaders = Object.keys(headers).reduce((acc, headerKey) => {
      acc.append(headerKey, headers[headerKey]);
      return acc;
    }, new Headers());

    finalHeaders.set(HTTP_HEADER_ACCEPT, MIME_TYPE_JSON);

    if (
      method === HTTP_METHOD_POST ||
      method === HTTP_METHOD_PUT ||
      method === HTTP_METHOD_DELETE ||
      method === HTTP_METHOD_PATCH
    ) {
      finalHeaders.set(HTTP_HEADER_CONTENT_TYPE, MIME_TYPE_JSON);
    }

    finalHeaders.set(HTTP_API_KEY, REACT_APP_API_KEY);

    const requestDescription =
      method === HTTP_METHOD_POST ||
      method === HTTP_METHOD_PUT ||
      method === HTTP_METHOD_DELETE ||
      method === HTTP_METHOD_PATCH
        ? {
            method,
            headers: finalHeaders,
            body: JSON.stringify(body),
            [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
          }
        : {
            method,
            headers: finalHeaders,
            [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
          };

    let response;
    try {
      response = await fetch(finalUrl, requestDescription);
    } catch (error) {
      response = error;
    }

    let responseBody = {};
    try {
      responseBody = await response.json();
    } catch (error) {}

    if (response.ok) {
      return responseBody;
    } else if (responseBody && Object.keys(responseBody).length > 0) {
      throw new Error(responseBody.message);
    } else {
      try {
        const responseText = await response.text();
        throw new Error(responseText);
      } catch (error) {
        throw new Error('Internal Server Error');
      }
    }
  }

  async function makeBlobRequest({
    method,
    url,
    query = {},
    body = {},
    headers = {},
  }) {
    const finalUrl =
      query && Object.keys(query).length
        ? `${url}?${qs.stringify(query)}`
        : url;

    const finalHeaders = Object.keys(headers).reduce((acc, headerKey) => {
      acc.append(headerKey, headers[headerKey]);
      return acc;
    }, new Headers());

    finalHeaders.set(HTTP_HEADER_ACCEPT, MIME_TYPE_JSON);

    if (
      method === HTTP_METHOD_POST ||
      method === HTTP_METHOD_PUT ||
      method === HTTP_METHOD_DELETE ||
      method === HTTP_METHOD_PATCH
    ) {
      finalHeaders.set(HTTP_HEADER_CONTENT_TYPE, MIME_TYPE_JSON);
    }

    finalHeaders.set(HTTP_API_KEY, REACT_APP_API_KEY);

    const requestDescription =
      method === HTTP_METHOD_POST ||
      method === HTTP_METHOD_PUT ||
      method === HTTP_METHOD_DELETE ||
      method === HTTP_METHOD_PATCH
        ? {
            method,
            headers: finalHeaders,
            body: JSON.stringify(body),
            [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
          }
        : {
            method,
            headers: finalHeaders,
            [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
          };

    let response;
    try {
      response = await fetch(finalUrl, requestDescription);
    } catch (error) {
      response = error;
    }

    if (response.ok) {
      return response.blob();
    } else {
      throw new Error('Internal Server Error');
    }
  }

}
