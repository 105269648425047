import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import StyledModal from '../../tools/StyledModal';

export default function InactivityModal(props) {
  const {
    open,
    handleClose,
    onConfirmationComplete,
    title,
    remaining,
    seconds,
  } = props;

  return (
    <StyledModal
      open={open}
      handleClose={handleClose}
      disableClose={true}
      title={title}
      confirmationText="Continuar en la sesión"
      cancelationText="Cerrar sesión"
      onConfirmationComplete={onConfirmationComplete}>
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">
         La sesión se cerrará pronto por inactividad y será redirigido a la ficha médica en {remaining} {seconds}. ¿Desea continuar en la sesión?
        </Typography>
        </Grid>
      </Grid>
    </StyledModal>
  );
}
