import { useEffect, useState } from 'react';
import { TimeProgressBar } from 'time-progress-bar';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Divider,
  Grid,
  Chip,
  Stack,
  Avatar,
  Tooltip,
} from '@mui/material';

// My components
import Chart from '../Chart';

// My styles
import './CardFormated.css';

export default function CardFormated(props) {
  const {
    hasProjects,
    isLoadingProjects,
    getProjects,
    resetScopeList,
    setProject,
    projects,
    loadingUpdate,
    selectedTag,
    selectedMember,
    handleSelectedMember,
    handleSelectedTag,
    email,
    admin,
    hideFinished,
    myProjects,
  } = props;
  useEffect(() => {
    if (!hasProjects && !isLoadingProjects) {
      getProjects();
    }

    resetScopeList();
  });
  const navigate = useNavigate();
  const handleClick = async project => {
    await setProject(project);
    navigate('/project');
  };
  if (hasProjects && admin !== undefined) {
    loadingUpdate(false);
    return (
      <Grid
        container
        justifyContent="left"
        alignItems="left"
        spacing={{ xs: 1, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ marginBottom: '2em' }}>
        {projects.map(project => {
          return (!hideFinished ||
            (hideFinished && project.progress !== 'Finished')) &&
            ((myProjects && admin) ||
              (!myProjects && !admin) ||
              (myProjects &&
                !admin &&
                project.members_emails.includes(email)) ||
              (!myProjects &&
                admin &&
                project.members_emails.includes(email))) &&
            ((selectedMember.length === 0 && selectedTag.length === 0) ||
              (selectedTag.length === 0 &&
                selectedMember.every(member =>
                  project.visible_members.includes(member),
                )) ||
              (selectedMember.length === 0 &&
                selectedTag.every(tag => project.tags.includes(tag))) ||
              (selectedMember.every(member =>
                project.visible_members.includes(member),
              ) &&
                selectedTag.every(tag => project.tags.includes(tag)))) ? (
            <Grid item xs={4} sx={{ textAlign: 'center', display: 'flex' }}>
              <Card
                onClick={() => handleClick(project)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  width: '100%',
                  height: '35rem',
                }}>
                <CardActionArea sx={{ height: '100%' }}>
                  <CardContent sx={{ height: '15rem' }}>
                    <Typography variant="h5" component="div">
                      {project.name.charAt(0).toUpperCase() +
                        project.name.slice(1)}
                    </Typography>
                    <Grid sx={{ marginTop: '1rem' }}>
                      <Chart
                        snapshots={project.snapshots}
                        width="300"
                        height="150"
                        preview="true"
                        key={
                          projects +
                          selectedTag +
                          selectedMember +
                          hideFinished +
                          myProjects
                        }
                      />
                    </Grid>
                    <CardContent>
                      <TimeProgressBar
                        data={[
                          {
                            start: project.progress_time[0],
                            end: project.progress_time[1],
                            className: 'progress-time',
                            style: { cursor: 'pointer' },
                            color: 'lightgreen',
                            children: (
                              <Tooltip
                                title="Progress"
                                arrow
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'lightgreen',
                                      '& .MuiTooltip-arrow': {
                                        color: 'lightgreen',
                                      },
                                    },
                                  },
                                }}>
                                <div>
                                  <div className="timebar-item">Progress</div>
                                  {project.progress}
                                </div>
                              </Tooltip>
                            ),
                          },
                          {
                            start: project.cycle_time[0],
                            end: project.cycle_time[1],
                            className: 'cycle-time',
                            style: { cursor: 'pointer' },
                            color: 'lightblue',
                            children: (
                              <Tooltip
                                title="Cycle"
                                arrow
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'lightblue',
                                      '& .MuiTooltip-arrow': {
                                        color: 'lightblue',
                                      },
                                    },
                                  },
                                }}>
                                <div>
                                  <div className="timebar-item">Cycle</div>
                                  {parseInt(project.cycle_length) > 1 ? (
                                    project.cycle_length
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Tooltip>
                            ),
                          },
                          {
                            start: project.cooldown_time[0],
                            end: project.cooldown_time[1],
                            className: 'cool-down-time',
                            color: 'lightgray',
                            children: (
                              <Tooltip
                                title="Cooldown"
                                arrow
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'lightgray',
                                      '& .MuiTooltip-arrow': {
                                        color: 'lightgray',
                                      },
                                    },
                                  },
                                }}>
                                <div>
                                  <div className="timebar-item">Cooldown</div>

                                  {parseInt(project.cooldown_length) > 1 ? (
                                    project.cooldown_length
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Tooltip>
                            ),
                          },
                        ]}
                        hourBar={<></>}
                      />
                    </CardContent>
                  </CardContent>
                  <CardContent>
                    <Divider style={{ marginTop: '4rem' }}>Members</Divider>
                    <CardContent>
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                          overflowY:
                            Math.ceil(project.visible_members.length / 3) *
                              2.5 <=
                            5
                              ? 'hidden'
                              : 'scroll',
                          height: '5rem',
                        }}>
                        <Tooltip
                          title={
                            <div>
                              {
                                project.members_names[
                                  project.members_ids.indexOf(parseInt(project.responsible))
                                ]
                              }
                              <br />
                              {
                                project.members_roles[
                                  project.members_ids.indexOf(parseInt(project.responsible))
                                ]
                              }
                            </div>
                          }>
                          <Chip
                            sx={{ margin: 0.5 }}
                            avatar={
                              <Avatar
                                alt={project.members[
                                  project.members_ids.indexOf(parseInt(project.responsible))
                                ]
                                  .toString()
                                  .toUpperCase()}
                                src={
                                  project.members_avatars[
                                    project.members_ids.indexOf(parseInt(project.responsible))
                                  ]
                                }></Avatar>
                            }
                            label={project.members[
                              project.members_ids.indexOf(parseInt(project.responsible))
                            ]}
                            color="info"
                            variant="outlined"
                            clickable={true}
                            onClick={e => {
                              e.stopPropagation();
                              handleSelectedMember(project.members[
                                project.members_ids.indexOf(parseInt(project.responsible))
                              ]);
                            }}
                          />
                        </Tooltip>
                        {project.members.length > 0 ? (
                          project.members.map(member =>
                            project.members[
                              project.members_ids.indexOf(parseInt(project.responsible))
                            ] === member ? (
                              <></>
                            ) : project.members_roles[
                                project.members.indexOf(member)
                              ] !== 'Viewer' ? (
                              <Tooltip
                                title={
                                  <div>
                                    {
                                      project.members_names[
                                        project.members.indexOf(member)
                                      ]
                                    }
                                    <br />
                                    {
                                      project.members_roles[
                                        project.members.indexOf(member)
                                      ]
                                    }
                                  </div>
                                }>
                                <Chip
                                  sx={{ margin: 0.5 }}
                                  avatar={
                                    <Avatar
                                      alt={member}
                                      src={
                                        project.members_avatars[
                                          project.members.indexOf(member)
                                        ]
                                      }></Avatar>
                                  }
                                  label={member}
                                  tooltip={member}
                                  color="primary"
                                  variant="outlined"
                                  clickable={true}
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleSelectedMember(member);
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <></>
                            ),
                          )
                        ) : (
                          <>-</>
                        )}
                      </Stack>
                    </CardContent>
                    <Divider>Tags</Divider>
                    <CardContent>
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                          overflowY:
                            Math.ceil(project.tags.length / 3) * 2.5 <= 5
                              ? 'hidden'
                              : 'scroll',
                          height: '5rem',
                          marginBottom: '4rem',
                        }}>
                        {project.tags.length > 0 ? (
                          project.tags.map(tag => (
                            <Chip
                              sx={{ margin: 0.5 }}
                              label={tag.charAt(0).toUpperCase() + tag.slice(1)}
                              variant="outlined"
                              color="primary"
                              clickable={true}
                              onClick={e => {
                                e.stopPropagation();
                                handleSelectedTag(tag);
                              }}
                            />
                          ))
                        ) : (
                          <>-</>
                        )}
                      </Stack>
                    </CardContent>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ) : null;
        })}
      </Grid>
    );
  } else {
    if (isLoadingProjects) {
      //loading projects
      loadingUpdate(true);
      return null;
    } else {
      // no project found
      loadingUpdate(true);
      return null;
    }
  }
}
