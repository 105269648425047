import Cookies from 'js-cookie';
export function createReducer(initialState, handlersTable) {
  return function (state = initialState, action) {
    if (handlersTable.hasOwnProperty(action.type)) {
      return handlersTable[action.type](state, action);
    } else {
      return state;
    }
  };
}

export function insertIntoDatabase(dataObject, dbName, table) {
  var indexedDBOpenRequest = window.indexedDB.open(dbName, 1);
  
  indexedDBOpenRequest.onupgradeneeded = function (event) {
    var db = event.target.result;
    // Create the object store if it doesn't exist
    if (!db.objectStoreNames.contains(table)) {
      db.createObjectStore(table, { autoIncrement: true });
    }
  };

  indexedDBOpenRequest.onsuccess = function (event) {
    var db = event.target.result;
    var transaction = db.transaction(table, 'readwrite');
    var storeObj = transaction.objectStore(table);
    const data = {
      ...dataObject,
      access_token: Cookies.get('msal.acessToken'),
      api_url: process.env.REACT_APP_API_BASE_URL,
      api_key: process.env.REACT_APP_API_KEY,
    };
    storeObj.add(data);
  };
  
  indexedDBOpenRequest.onerror = function (error) {
    console.error('IndexedDB error:', error);
  };
}
