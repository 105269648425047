import { useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Checkbox,
  ListItemButton,
  Paper,
  Divider,
  Grid,
  Menu,
  MenuItem,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import {
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  PolarAngleAxis,
} from 'recharts';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Redux wrappers
import AddTaskDialog from '../../redux/containers/project/AddTaskDialog';
import EditScopeDialog from '../../redux/containers/project/EditScopeDialog';

// My components
import DeleteDialog from '../DeleteDialog';

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = [...source];
  const destClone = [...destination];
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};

  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export default function Scope(props) {
  const {
    scope,
    role,
    loadingUpdate,
    updateTask,
    getScopes,
    deleteScope,
    created_by,
    users,
    deleteTask,
    getProjects,
    getRole,
    project,
  } = props;
  const {
    tasks_ms,
    tasks_ns,
    name,
    id,
    completed_ns,
    completed_ms,
    total_tasks,
  } = scope;
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditScopeDialog, setOpenEditScopeDialog] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const openMenu = Boolean(anchorEl);
  const color = scope.color + '0F';
  const admin = getRole.admin;
  const user_id = getRole.user_id;
  const project_id = project.id;

  var listas = {
    must_have: tasks_ms,
    nice_to_have: tasks_ns,
  };
  const id2List = {
    droppable: 'must_have',
    droppable2: 'nice_to_have',
  };

  const getList = id => listas[id2List[id]];

  const sortLists = () => {
    var tasks_ms = listas.must_have.sort((a, b) => a.order - b.order);

    var tasks_ns = listas.nice_to_have.sort((a, b) => a.order - b.order);
    tasks_ms = listas.must_have.sort(
      (x, y) => Number(x.is_completed) - Number(y.is_completed),
    );
    tasks_ns = listas.nice_to_have.sort(
      (x, y) => Number(x.is_completed) - Number(y.is_completed),
    );

    listas = {
      must_have: tasks_ms,
      nice_to_have: tasks_ns,
    };
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const must_have = reorder(
        getList(source.droppableId),
        source.index,
        destination.index,
      );

      let state = { ...listas, must_have };

      if (source.droppableId === 'droppable2') {
        state = { ...listas, nice_to_have: must_have };
      }

      listas = state;

      //update the task
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );
      if (destination.droppableId === 'droppable') {
        result.droppable[destination.index].is_nice_to_have = false;
      } else {
        result.droppable2[destination.index].is_nice_to_have = true;
      }
      listas = {
        must_have: result.droppable,
        nice_to_have: result.droppable2,
      };
    }
    const must_haves = listas.must_have.map((element, index) => {
      return [element.id, index, element.is_completed, element.is_nice_to_have];
    });
    const nice_to_haves = listas.nice_to_have.map((element, index) => {
      return [element.id, index, element.is_completed, element.is_nice_to_have];
    });
    handleUpdate([...must_haves, ...nice_to_haves].flat());
  };

  sortLists();

  var ns_prop = (completed_ns / tasks_ns.length) * 100;
  var ms_prop = (completed_ms / tasks_ms.length) * 100;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseScopeDialog = () => {
    handleClose();
    setOpenEditScopeDialog(!openEditScopeDialog);
  };

  const handleUpdate = async tasks => {
    loadingUpdate(true);
    await updateTask({ tasks });
    await getScopes(project_id);
    loadingUpdate(false);
  };

  const handleDelete = async () => {
    handleClose();
    setOpenDeleteDialog(true);
  };

  const handleDeleteTask = async task_id => {
    loadingUpdate(true);
    await deleteTask(task_id);
    await getScopes(project_id);
    loadingUpdate(false);
  };

  const handleDeleteMode = () => {
    handleClose();
    setDeleteMode(!deleteMode);
  };

  const handleToggle = value => () => {
    if (role === 'Member' || admin) {
      const currentIndex = checked.indexOf(value);
      var newChecked = [...checked];
      value.is_completed = !value.is_completed;
      handleUpdate([
        value.id,
        value.order,
        value.is_completed,
        value.is_nice_to_have,
      ]);
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    }
  };
  if (tasks_ns.length === 0) {
    ns_prop = 100;
  }

  if (ns_prop === Infinity || isNaN(ns_prop)) {
    ns_prop = 0;
  }

  if (ms_prop === Infinity || isNaN(ms_prop)) {
    ms_prop = 0;
  }
  const data = [
    {
      name: 'nice to have',
      uv: ns_prop,
      fill: '#0070B7',
    },
    {
      name: 'must have',
      uv: ms_prop,
      fill: '#46bf83',
    },
  ];

  const height =
    !props.isDragging &&
    (role === 'Member' ||
      role === 'Project Manager' ||
      role === 'Viewer' ||
      admin)
      ? '600px'
      : '100px';

  return (
    <Paper elevation={3} sx={{ margin: '10px' }}>
      <Card sx={{ height: height, backgroundColor: color }}>
        <CardContent>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="subtitle2" component="h2">
                {completed_ns + completed_ms}/{total_tasks} completed
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {role === 'Member' || admin ? (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MenuIcon fontSize="small" />
                  </Button>
                  {!deleteMode ? (
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <MenuItem
                        onClick={handleOpen}
                        sx={{ backgroundColor: color }}>
                        <AddIcon />
                        Add new task
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseScopeDialog}
                        sx={{ backgroundColor: color }}>
                        <EditIcon />
                        Edit scope
                      </MenuItem>
                      <MenuItem
                        onClick={handleDeleteMode}
                        sx={{ backgroundColor: color }}>
                        <DeleteIcon />
                        Delete task
                      </MenuItem>
                      <MenuItem
                        onClick={handleDelete}
                        sx={{ backgroundColor: color }}>
                        <DeleteIcon />
                        Delete scope
                      </MenuItem>
                    </Menu>
                  ) : (
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <MenuItem
                        onClick={handleDeleteMode}
                        sx={{ backgroundColor: color }}>
                        <EditOffIcon />
                        Cancel delete
                      </MenuItem>
                    </Menu>
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={2}>
              <ResponsiveContainer maxHeight={32}>
                <RadialBarChart
                  innerRadius={'30%'}
                  outerRadius={'100%'}
                  barSize={4}
                  data={data}
                  margin={'-4em'}
                  startAngle={0}
                  endAngle={360}>
                  <PolarAngleAxis
                    type="number"
                    domain={[0, 100]}
                    dataKey={'uv'}
                    angleAxisId={0}
                    tick={false}
                  />
                  <RadialBar
                    background
                    dataKey="uv"
                    angleAxisId={0}
                    data={data}
                  />
                </RadialBarChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" component="h2">
                {name}
              </Typography>
            </Grid>
          </Grid>
          {!props.isDragging &&
          (role === 'Member' ||
            role === 'Project Manager' ||
            role === 'Viewer' ||
            admin) ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Box margin={1}>
                <Divider> Must have </Divider>
              </Box>
              <Droppable droppableId="droppable">
                {provided => (
                  <List
                    sx={{ overflow: 'auto', height: '250px' }}
                    ref={provided.innerRef}>
                    {listas.must_have.length > 0 ? (
                      listas.must_have.map((item, index) => {
                        const labelId = `checkbox-list-label-${item}`;
                        const dragid = `draggable-${item.id}`;
                        return (
                          <Draggable
                            key={dragid}
                            draggableId={dragid}
                            index={index}
                            isDragDisabled={role !== 'Member' && !admin}>
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <ListItem>
                                  <ListItemButton
                                    disableRipple
                                    dense
                                    onClick={handleToggle(item)}>
                                    <Checkbox
                                      edge="start"
                                      disableRipple
                                      checked={item.is_completed}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                    <ListItemText primary={item.name} />
                                  </ListItemButton>
                                  {deleteMode ? (
                                    <ListItemSecondaryAction>
                                      <IconButton
                                        onClick={() =>
                                          handleDeleteTask(item.id)
                                        }>
                                        <DeleteIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  ) : null}
                                </ListItem>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <ListItem>
                        <ListItemText
                          primary={
                            "This project doesn't have must haves, you can add one using the menu in the top right corner"
                          }></ListItemText>
                      </ListItem>
                    )}

                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
              <Box margin={1}>
                <Divider> Nice to have </Divider>
              </Box>
              <Droppable droppableId="droppable2">
                {provided => (
                  <List
                    sx={{ overflow: 'auto', height: '150px' }}
                    ref={provided.innerRef}>
                    {listas.nice_to_have.length > 0 ? (
                      listas.nice_to_have.map((item, index) => {
                        const labelId = `checkbox-list-label-${item}`;
                        const dragid = `draggable-${item.id}`;
                        return (
                          <Draggable
                            key={dragid}
                            draggableId={dragid}
                            index={index}
                            isD
                            isDragDisabled={role !== 'Member' && !admin}>
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <ListItem>
                                  <ListItemButton
                                    disableRipple
                                    dense
                                    onClick={handleToggle(item)}>
                                    <Checkbox
                                      edge="start"
                                      disableRipple
                                      checked={item.is_completed}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                    <ListItemText primary={item.name} />
                                  </ListItemButton>
                                  {deleteMode ? (
                                    <ListItemSecondaryAction>
                                      <IconButton
                                        onClick={() =>
                                          handleDeleteTask(item.id)
                                        }>
                                        <DeleteIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  ) : null}
                                </ListItem>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <ListItem>
                        <ListItemText
                          sx={{ textAlign: 'center' }}
                          primary={'No tasks'}></ListItemText>
                      </ListItem>
                    )}

                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
      <AddTaskDialog
        scope={scope}
        open={open}
        close={handleOpen}
        scope_id={id}
        created_by={created_by}
        project={project}
        users={users}
        user_id={user_id}
      />
      <DeleteDialog
        open={openDeleteDialog}
        toDelete={id}
        toDeleteName={'scope'}
        deleteFunction={deleteScope}
        getFunction={getScopes}
        optionalGetId={project_id}
        loadingUpdate={loadingUpdate}
        getSecondary={getProjects}
        closeDialog={setOpenDeleteDialog}
        goHome={false}
      />
      <EditScopeDialog
        open={openEditScopeDialog}
        closeDialog={handleCloseScopeDialog}
        scope={scope}
        project_id={project_id}
      />
    </Paper>
  );
}
