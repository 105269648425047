import { createReducer } from './utils.js';

const {
  REACT_APP_LOGING_MODE,
  REACT_APP_LOGIN_URL_EXTERNAL,
  REACT_APP_LOGIN_URL_INTERNAL,
  REACT_APP_BASE_URL_INTERNAL,
  REACT_APP_BASE_URL_EXTERNAL,
} = process.env;

import {
  COOKIE_VERIFY_REQUEST,
  COOKIE_VERIFY_AVAILABLE,
  COOKIE_VERIFY_UNAVAILABLE,
} from './constants.js';

const defaultState = {
  loadingVerify: false,
  cookie: null,
  verifyState: false,
};

export const reducer = createReducer(defaultState, {
  [COOKIE_VERIFY_REQUEST]: handleCookieVerifyRequest,
  [COOKIE_VERIFY_AVAILABLE]: handleCookieVerifyAvailable,
  [COOKIE_VERIFY_UNAVAILABLE]: handleCookieVerifyUnavailable,
});

function handleCookieVerifyRequest(state, _) {
  return {
    ...state,
    loadingVerify: true,
  };
}

function handleCookieVerifyAvailable(state, _) {
  return {
    ...state,
    loadingVerify: false,
    verifyState: true,
  };
}

function handleCookieVerifyUnavailable(state, _) {
  return defaultState;
}

export function verifyCookie() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({
      type: COOKIE_VERIFY_AVAILABLE,
      payload: { cookie },
    });
  };
}
