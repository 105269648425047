import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { MdOutlineAnalytics } from 'react-icons/md';
import { ChevronForwardOutline } from 'react-ionicons';
import { ChevronBackOutline } from 'react-ionicons';
import { LuFileText } from 'react-icons/lu';
import { LuFileCheck } from 'react-icons/lu';
import { PiCurrencyCircleDollar } from 'react-icons/pi';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { GoPerson } from 'react-icons/go';
import { MdOutlineMedicalServices } from 'react-icons/md';
import { MdHistoryEdu } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoSettingsOutline } from 'react-icons/io5';
import { LuPackage } from 'react-icons/lu';
import { PiBlueprint } from 'react-icons/pi';
import { BsChatLeftText } from 'react-icons/bs';
import { RiMailSendFill } from 'react-icons/ri';
import { MdCampaign } from 'react-icons/md';
import { MdOutlineQuickreply } from 'react-icons/md';
import { TbLogs } from 'react-icons/tb';
import { AiOutlineDashboard } from 'react-icons/ai';
import { RiSurveyLine } from 'react-icons/ri';

const styles = {
  icon: {
    color: 'white',
  },
};

const mainListItems = [
  { text: 'Proyectos', icon: <MdOutlineAnalytics size={25} />, link: '/' },
];

const secondaryListItems = [
  { text: 'Logs', icon: <TbLogs size={25} />, link: '/logs' },
  { text: 'Configuración', icon: <SettingsRoundedIcon />, link: '/AdminView' },
];

export default function MenuContent() {
  const [mainSelected, setMainSelected] = React.useState(0);
  const [secondarySelected, setSecondarySelected] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    const { pathname } = location;
    const mainIndex = mainListItems.findIndex(item => item.link === pathname);
    if (mainIndex !== -1) {
      setMainSelected(mainIndex);
    }
    const secondaryIndex = secondaryListItems.findIndex(
      item => item.link === pathname,
    );
    if (secondaryIndex !== -1) {
      setSecondarySelected(secondaryIndex);
    }
  }, []);

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={index === mainSelected}
              onClick={() =>
                navigate(item.link) ||
                setMainSelected(index) ||
                setSecondarySelected(null)
              }>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={index === secondarySelected}
              onClick={() =>
                navigate(item.link) ||
                setSecondarySelected(index) ||
                setMainSelected(null)
              }>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
