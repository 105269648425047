import { createReducer } from './utils.js';

import {
  TASKS_POST,
  TASKS_POSTED,
  TASKS_POST_FAILED,
  TASKS_DELETE,
  TASKS_DELETED,
  TASKS_DELETE_FAILED,
} from './constants.js';

const defaultState = {
  sent: false,
  posting_tasks: false,
  deleting_tasks: false,
};

export const reducer = createReducer(defaultState, {
  [TASKS_POST]: handleTasksPost,
  [TASKS_POSTED]: handleTasksPostSuccess,
  [TASKS_POST_FAILED]: handleTasksPostFailure,
  [TASKS_DELETE]: handleTasksDelete,
  [TASKS_DELETED]: handleTasksDeleted,
  [TASKS_DELETE_FAILED]: handleTasksDeleteFailure,
});

function handleTasksDelete(state, _) {
  return {
    ...state,
    deleting_tasks: true,
  };
}

function handleTasksDeleted(state, _) {
  return {
    ...state,
    deleting_tasks: false,
  };
}

function handleTasksDeleteFailure(state, _) {
  return defaultState;
}

function handleTasksPost(state, _) {
  return {
    ...state,
    posting_tasks: true,
  };
}

function handleTasksPostSuccess(state, _) {
  return {
    ...state,
    sent: true,
    posting_tasks: false,
  };
}

function handleTasksPostFailure(state, _) {
  return defaultState;
}

export function postTasks(tasks) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: TASKS_POST });
      await dataSource.postTasks(tasks);
      dispatch({ type: TASKS_POSTED });
    } catch (error) {
      dispatch({
        type: TASKS_POST_FAILED,
        payload: { error },
      });
    }
  };
}

export function updateTask(task) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: TASKS_POST });
      await dataSource.updateTask(task);
      dispatch({ type: TASKS_POSTED });
    } catch (error) {
      dispatch({
        type: TASKS_POST_FAILED,
        payload: { error },
      });
    }
  };
}

export function deleteTask(taskId) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: TASKS_DELETE });
      await dataSource.deleteTask(taskId);
      dispatch({ type: TASKS_DELETED });
    } catch (error) {
      console.log(error);
      dispatch({
        type: TASKS_DELETE_FAILED,
        payload: { error },
      });
    }
  };
}
