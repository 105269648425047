import { createReducer } from './utils.js';

import {
  SNAPSHOTS_POST,
  SNAPSHOTS_POSTED,
  SNAPSHOTS_POST_FAILED,
} from './constants.js';

const defaultState = {
  sent: false,
  posting_snapshots: false,
};

export const reducer = createReducer(defaultState, {
  [SNAPSHOTS_POST]: handleSnapshotsPost,
  [SNAPSHOTS_POSTED]: handleSnapshotsPostSuccess,
  [SNAPSHOTS_POST_FAILED]: handleSnapshotsPostFailure,
});

function handleSnapshotsPost(state, _) {
  return {
    ...state,
    posting_snapshots: true,
  };
}

function handleSnapshotsPostSuccess(state, _) {
  return {
    ...state,
    sent: true,
    posting_snapshots: false,
  };
}

function handleSnapshotsPostFailure(state, _) {
  return defaultState;
}

export function postSnapshots(snapshots) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: SNAPSHOTS_POST });
      await dataSource.postSnapshots(snapshots);
      dispatch({ type: SNAPSHOTS_POSTED });
    } catch (error) {
      dispatch({
        type: SNAPSHOTS_POST_FAILED,
        payload: { error },
      });
    }
  };
}
