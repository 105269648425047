import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';

export default function AddTaskDialog(props) {
  const {
    scope_id,
    project,
    created_by,
    getScopes,
    getProjects,
    open,
    loadingUpdate,
    close,
    postTasks,
  } = props;
  const project_id = project.id;
  const [formValues, setFormValues] = useState({
    scope_id,
    task_name: '',
    description: '',
    is_nice_to_have: false,
    created_by,
    supervisor: project.responsible_id,
  });

  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleChangeCheck = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.checked });
  };
  const handleClose = () => {
    setFormValues({
      scope_id,
      task_name: '',
      description: '',
      is_nice_to_have: false,
      created_by,
      supervisor: project.responsible_id,
    });
    close();
  };
  const handleSubmit = async () => {
    const newForm = { ...formValues, scope_id };
    handleClose();
    loadingUpdate(true);
    await postTasks(newForm);
    await getScopes(project_id);
    getProjects();
    loadingUpdate(false);
  };
  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>Add Task</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom="1em">
          To add a task, please fill out the form below.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              margin="dense"
              id="task-name"
              name="task_name"
              label="Task Name"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              defaultValue=""
              multiline
              margin="dense"
              id="description"
              name="description"
              label="Description"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              required
              margin="dense"
              label="Supervisor"
              type="text"
              name="supervisor"
              fullWidth
              variant="outlined"
              value={formValues.supervisor}
              onChange={handleChange}>
              {project.members_ids.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {project.members[index]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeCheck}
                    name="is_nice_to_have"
                  />
                }
                label="Nice to have"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          marginLeft: '1em',
          marginRight: '1em',
        }}>
        <Button onClick={handleClose}>Cancel</Button>
        {formValues.task_name !== '' && formValues.supervisor !== '' ? (
          <Button onClick={handleSubmit}>Add task</Button>
        ) : (
          <Button disabled onClick={handleSubmit}>
            Add task
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
