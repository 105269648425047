import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cookies from 'js-cookie'

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://xavpr.com">
        Javier Pérez
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login(props) {


  const [state, setState] = React.useState({
    email: '',
    password: '',
    userName: '',
    firstName: '',
    lastName: '',
    mode: 'login',
  });

  React.useEffect(() => {
    if (props.getRole?.session_token) { 
        // save session_token in cookies in .xavpr.com domain
        Cookies.set('session_token', props.getRole.session_token, { domain: '.xavpr.com' });
        window.location.reload();
    }
    }, [props.getRole]);


  const handleRegister = event => {
    event.preventDefault();
    props.loadUserRoles(state)
    };



  const handleLogin = event => {
    event.preventDefault();
    props.loadUserRoles(state)
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              'url("/static/images/templates/templates-images/sign-in-side-bg.png")',
            backgroundColor: t =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'left',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={state.mode === 'login' ? handleLogin : handleRegister}
              sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={e => setState({ ...state, email: e.target.value })}
                value={state.email}
                autoComplete="email"
                autoFocus
              />
              {state.mode === 'register' && (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    onChange={e => setState({ ...state, userName: e.target.value })}
                    autoComplete="username"
                    value={state.userName}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="firstName"
                    onChange={e => setState({ ...state, firstName: e.target.value })}
                    value={state.firstName}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lastName"
                    onChange={e => setState({ ...state, lastName: e.target.value })}
                    value={state.lastName}
                  />
                </>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => setState({ ...state, password: e.target.value })}
                value={state.password}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                {state.mode === 'login' ? 'Iniciar sesión' : 'Registrarse'}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" disabled>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  {state.mode === 'login' ? (
                    <Link
                      href="#"
                      variant="body2"
                      onClick={() => setState({ mode: 'register' })}>
                      {'¿No tienes cuenta? Registrate'}
                    </Link>
                  ) : (
                    <Link
                      href="#"
                      variant="body2"
                      onClick={() => setState({ mode: 'login' })}>
                      {'¿Ya tienes cuenta? Inicia sesión'}
                    </Link>
                  )}
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
