import { createReducer } from './utils.js';

import {
  USERS_AVAILABLE,
  USERS_UNAVAILABLE,
  USERS_USERNAME_AVAILABLE,
  USER_PROFILE_REQUEST,
  USER_PROFILE_AVAILABLE,
  USER_PROFILE_UNAVAILABLE,
  USER_LOGOUT,
  USER_ROLES_REQUEST,
  USER_ROLES_AVAILABLE,
  USER_ROLES_UNAVAILABLE,
  USER_SESSION_SET,
  USER_UPDATE,
  USER_UPDATED,
  USER_UPDATE_FAILED,
  USER_DELETE,
  USER_DELETED,
  USER_DELETE_FAILED,
  USER_MY_PROJECTS,
  USER_HIDE_FINISHED,
} from './constants.js';

const defaultState = {
  loading_users: false,
  users: false,
  username: '',
  loading_profile: false,
  profile: {},
  profileState: true, //TODO: change to false
  sesion: false,
  roles: '',
  loading_roles: false,
  sent: false,
  updating_tags: false,
  deleting_tags: false,
  hideFinishedProjects: true,
  showMyProjects: true,
};

export const reducer = createReducer(defaultState, {
  [USERS_AVAILABLE]: handleUsersAvailable,
  [USERS_UNAVAILABLE]: handleUsersUnavailable,
  [USERS_USERNAME_AVAILABLE]: handleUsernameAvailable,
  [USER_PROFILE_REQUEST]: handleProfileRequest,
  [USER_PROFILE_AVAILABLE]: handleProfileAvailable,
  [USER_PROFILE_UNAVAILABLE]: handleProfileUnavailable,
  [USER_LOGOUT]: handleLogOut,
  [USER_SESSION_SET]: handleSessionSet,
  [USER_ROLES_REQUEST]: handleRoleRequest,
  [USER_ROLES_AVAILABLE]: handleRoleAvailable,
  [USER_ROLES_UNAVAILABLE]: handleRoleUnavailable,
  [USER_UPDATE]: handleUserUpdate,
  [USER_UPDATED]: handleUserUpdateSuccess,
  [USER_UPDATE_FAILED]: handleUserUpdateFailure,
  [USER_DELETE]: handleUserDelete,
  [USER_DELETED]: handleUserDeleted,
  [USER_DELETE_FAILED]: handleUserDeleteFailure,
  [USER_MY_PROJECTS]: handleMyProjects,
  [USER_HIDE_FINISHED]: handleHideFinished,
});

function handleMyProjects(state, { payload: { myProjects } }) {
  return {
    ...state,
    myProjects: myProjects,
  };
}

function handleHideFinished(state, { payload: { hideFinished } }) {
  return {
    ...state,
    hideFinished: hideFinished,
  };
}

function handleUserDelete(state, _) {
  return {
    ...state,
    deleting_tags: true,
  };
}

function handleUserDeleted(state, _) {
  return {
    ...state,
    deleting_tags: false,
  };
}

function handleUserDeleteFailure(state, _) {
  return defaultState;
}

function handleUserUpdate(state, _) {
  return {
    ...state,
    updating_tags: true,
  };
}

function handleUserUpdateSuccess(state, _) {
  return {
    ...state,
    sent: true,
    updating_tags: false,
  };
}

function handleUserUpdateFailure(state, _) {
  return defaultState;
}

function handleRoleRequest(state, _) {
  return {
    ...state,
    loading_roles: true,
  };
}

function handleRoleAvailable(state, { payload: { roles } }) {
  return {
    ...state,
    roles: roles,
    loading_roles: false,
  };
}

function handleRoleUnavailable(state, _) {
  return defaultState;
}

function handleSessionSet(state, { payload: { session } }) {
  return {
    ...state,
    session,
  };
}

function handleLogOut(state, { payload: { profile } }) {
  return {
    ...state,
    profile: profile,
  };
}

function handleProfileRequest(state, _) {
  return {
    ...state,
    loading_profile: true,
  };
}

function handleProfileAvailable(state, { payload: { profile } }) {
  return {
    ...state,
    profile: profile,
    loading_profile: false,
    profileState: true,
  };
}

function handleProfileUnavailable(state, _) {
  return defaultState;
}

function handleUsersUnavailable(state, _) {
  return defaultState;
}

function handleUsernameAvailable(state, { payload: { username } }) {
  return {
    ...state,
    username: username,
  };
}

function handleUsersAvailable(state, { payload: { users } }) {
  return {
    ...state,
    users: true,
    userList: users,
  };
}

export function getUsers() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      const users = await dataSource.getUsers();
      dispatch({
        type: USERS_AVAILABLE,
        payload: { users },
      });
    } catch (error) {
      dispatch({
        type: USERS_UNAVAILABLE,
        payload: { error },
      });
    }
  };
}

export function getProfile(token) {
  return async (dispatch, getState, { services: { dataSource } }) => {

    try {
      const profile = await dataSource.getProfile(token);
      dispatch({
        type: USER_PROFILE_AVAILABLE,
        payload: { profile },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: USER_PROFILE_UNAVAILABLE,
        payload: { error },
      });
    }
  };
}

export function logOut() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      const logout = await dataSource.logOut();
      dispatch({
        type: USER_LOGOUT,
        payload: { logout },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: USER_PROFILE_UNAVAILABLE,
        payload: { error },
      });
    }
  };
}

export function setSession(session) {
  return async dispatch => {
    dispatch({
      type: USER_SESSION_SET,
      payload: { session },
    });
  };
}

export function setShowMyProjects(myProjects) {
  return async dispatch => {
    dispatch({
      type: USER_MY_PROJECTS,
      payload: { myProjects },
    });
  };
}

export function setHideFinishedProjects(hideFinished) {
  return async dispatch => {
    dispatch({
      type: USER_HIDE_FINISHED,
      payload: { hideFinished },
    });
  };
}

export function loadUserRoles(profile) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: USER_ROLES_REQUEST });
    try {
      const roles = await dataSource.loadUserRoles(profile);
      dispatch({
        type: USER_ROLES_AVAILABLE,
        payload: { roles },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: USER_ROLES_UNAVAILABLE,
        payload: { error },
      });
    }
  };
}

export function updateUser(user) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: USER_UPDATE });
      await dataSource.updateUser(user);
      dispatch({ type: USER_UPDATED });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAILED,
        payload: { error },
      });
    }
  };
}

export function deleteUser(userId) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: USER_DELETE });
      await dataSource.deleteUser(userId);
      dispatch({ type: USER_DELETED });
    } catch (error) {
      dispatch({
        type: USER_DELETE_FAILED,
        payload: { error },
      });
    }
  };
}
