import { connect } from 'react-redux';
import Navbar from '../../../components/navbar/Navbar';
import { setSnackAlert } from 'core/slices/snackAlert';
import { isLoading, snack } from 'core/selectors';

export default connect(
  state => ({
    snack: snack(state),
    isLoading: isLoading(state),
  }),
  { setSnackAlert },
)(Navbar);
