import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Paper, Grid, Divider, Typography, Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Redux wrappers
import UsersCard from '../../redux/containers/user/UsersCard';
import TagsCard from '../../redux/containers/user/TagsCard';
//My components

export default function AdminView(props) {
  const navigate = useNavigate();
  const { admin, user_id } = props.getRole;

  if (admin) {
    return (
      <div>
        <Paper
          elevation={3}
          sx={{ marginTop: '5em' }}
          style={{
            width: '100%',
            height: '100%',
          }}>
          <Grid container xs={12}>
            <Grid item xs={3}>
              <Box
                display="flex"
                justifyContent="flex-start"
                marginLeft={'1em'}
                marginTop={'1em'}>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/')}
                  startIcon={<ArrowBackIcon />}
                  size="small">
                  Go back
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" align="center" marginTop={2}>
                Admin dashboard
              </Typography>
            </Grid>
          </Grid>
          <Box margin={'1em'}>
            <Divider>Users</Divider>
          </Box>
          <Box margin={'3em'}>
            <Paper elevation={1}>
              <UsersCard user_id={user_id} />
            </Paper>
          </Box>
          <Box margin={'1em'}>
            <Divider>Tags</Divider>
          </Box>
          <Box margin={'3em'}>
            <Paper elevation={1}>
              <TagsCard user_id={user_id} />
            </Paper>
          </Box>
        </Paper>
      </div>
    );
  } else {
    return (
      <div>
        <b>Error: 403 Forbidden</b>
      </div>
    );
  }
}
