import { createReducer } from './utils.js';

import {
  TAGS_AVAILABLE,
  TAGS_UNAVAILABLE,
  TAGS_POST,
  TAGS_POSTED,
  TAGS_POST_FAILED,
  TAGS_DELETE,
  TAGS_DELETED,
  TAGS_DELETE_FAILED,
} from './constants.js';

const defaultState = {
  tags: false,
  tagList: [],
  sent: false,
  posting_tags: false,
  deleting_tags: false,
};

export const reducer = createReducer(defaultState, {
  [TAGS_AVAILABLE]: handleTagsAvailable,
  [TAGS_UNAVAILABLE]: handleTagsUnavailable,
  [TAGS_POST]: handleTagsPost,
  [TAGS_POSTED]: handleTagsPostSuccess,
  [TAGS_POST_FAILED]: handleTagsPostFailure,
  [TAGS_DELETE]: handleTagsDelete,
  [TAGS_DELETED]: handleTagsDeleted,
  [TAGS_DELETE_FAILED]: handleTagsDeleteFailure,
});

function handleTagsDelete(state, _) {
  return {
    ...state,
    deleting_tags: true,
  };
}

function handleTagsDeleted(state, _) {
  return {
    ...state,
    deleting_tags: false,
  };
}

function handleTagsDeleteFailure(state, _) {
  return defaultState;
}

function handleTagsPost(state, _) {
  return {
    ...state,
    posting_tags: true,
  };
}

function handleTagsPostSuccess(state, _) {
  return {
    ...state,
    sent: true,
    posting_tags: false,
  };
}

function handleTagsPostFailure(state, _) {
  return defaultState;
}

function handleTagsUnavailable(state, _) {
  return defaultState;
}

function handleTagsAvailable(state, { payload: { tags } }) {
  return {
    ...state,
    tags: true,
    tagList: tags,
  };
}

export function getTags() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      const tags = await dataSource.getTags();
      dispatch({
        type: TAGS_AVAILABLE,
        payload: { tags },
      });
    } catch (error) {
      dispatch({
        type: TAGS_UNAVAILABLE,
        payload: { error },
      });
    }
  };
}

export function postTags(tags) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: TAGS_POST });
      await dataSource.postTags(tags);
      dispatch({ type: TAGS_POSTED });
    } catch (error) {
      dispatch({
        type: TAGS_POST_FAILED,
        payload: { error },
      });
    }
  };
}

export function updateTags(tags) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: TAGS_POST });
      await dataSource.updateTags(tags);
      dispatch({ type: TAGS_POSTED });
    } catch (error) {
      dispatch({
        type: TAGS_POST_FAILED,
        payload: { error },
      });
    }
  };
}

export function deleteTags(tagId) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: TAGS_DELETE });
      await dataSource.deleteTags(tagId);
      dispatch({ type: TAGS_DELETED });
    } catch (error) {
      dispatch({
        type: TAGS_DELETE_FAILED,
        payload: { error },
      });
    }
  };
}
