import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { TimeProgressBar } from 'time-progress-bar';
import { PieChart, Pie } from 'recharts';
import {
  Paper,
  Grid,
  Divider,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import MenuIcon from '@mui/icons-material/Menu';
import EditOffIcon from '@mui/icons-material/EditOff';
import ReorderIcon from '@mui/icons-material/Reorder';
import CloseIcon from '@mui/icons-material/Close';
import TimelineIcon from '@mui/icons-material/Timeline';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

// Redux wrappers
import AddScopeDialog from '../../redux/containers/project/AddScopeDialog';
import EditProjectDialog from '../../redux/containers/project/EditProjectDialog';
import EditMembersDialog from '../../redux/containers/project/EditMembersDialog';
import EditTagsDialog from '../../redux/containers/project/EditTagsDialog';

//My components
import Chart from '../Chart';
import DeleteDialog from '../DeleteDialog';
import DragabbleCard from './DragabbleCard';
import Scope from '../../redux/containers/project/Scope';

export default function Project(props) {
  const {
    hasScopes,
    isLoadingScopes,
    scopes,
    hasUsers,
    getProfileData,
    project,
    loadingUpdate,
    getProjects,
    postSnapshots,
    getScopes,
    deleteProject,
    updateScopes,
    getRole,
  } = props;
  const admin = getRole.admin;
  const user_id = getRole.user_id;
  let users = props.users;
  const username = getProfileData.username;
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth / 1.3);
  const [height, setHeight] = useState(window.innerHeight / 3.2);
  const [preview, setPreview] = useState(true);
  const [openScopeDialog, setOpenScopeDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openMemberDialog, setOpenMemberDialog] = useState(false);
  const [openTagsDialog, setOpenTagsDialog] = useState(false);
  const [snapshotDict, setSnapshotDict] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [move, setMove] = useState(false);
  const [position, setPosition] = useState(-1);
  const [showHistory, setShowHistory] = useState(false);
  const openMenu = Boolean(anchorEl);
  window.onresize = resize;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openScope = () => {
    setOpenScopeDialog(true);
  };

  const closeScope = () => {
    setOpenScopeDialog(false);
  };

  const handleHistory = () => {
    setPosition(-1);
    setShowHistory(!showHistory);
  };

  const handleLeft = history_length => {
    if (position - 1 >= -history_length) {
      setPosition(position - 1);
    }
  };

  const handleRight = () => {
    if (position + 1 < 0) {
      setPosition(position + 1);
    }
  };

  const handleForwardLeft = history_length => {
    setPosition(-history_length);
  };

  const handleForwardRight = () => {
    setPosition(-1);
  };

  const handleEdit = () => {
    handleClose();
    setOpenEditDialog(!openEditDialog);
  };

  const handleMembers = () => {
    handleClose();
    setOpenMemberDialog(!openMemberDialog);
  };

  const handleTags = () => {
    handleClose();
    setOpenTagsDialog(!openTagsDialog);
  };

  const handlePreview = () => {
    setPreview(!preview);
  };

  const handleSave = async () => {
    loadingUpdate(true);
    await postSnapshots({ snapshots: JSON.stringify(snapshotDict) });
    getProjects();
    await getScopes(project.id);
    loadingUpdate(false);
    setPreview(!preview);
  };

  const handleDelete = async () => {
    handleClose();
    setOpenDeleteDialog(true);
  };

  const handleMove = async () => {
    setMove(!move);
  };
  useEffect(() => {
    const {
      hasScopes,
      isLoadingScopes,
      getScopes,
      hasUsers,
      getUsers,
      project,
      loadingUpdate,
    } = props;
    loadingUpdate(true);
    if (!hasScopes && !isLoadingScopes) {
      if (project.id) {
        getScopes(project.id);
      } else {
        navigate('/');
      }
    }
    if (!hasUsers) {
      getUsers();
    }
    window.scrollTo(0, 0); // Scroll to top after a route transition
  }, []);


  function resize() {
    if (
      Math.abs(window.innerWidth / 1.3 - width) > 200 ||
      Math.abs(window.innerHeight / 3.2 - height) > 10
    ) {
      setHeight(window.innerHeight / 3.2);
      setWidth(window.innerWidth / 1.3);
    }
  }

  if (hasScopes && hasUsers) {
    const dataPie = [];
    scopes.scopes?.forEach(element => {
      dataPie.push({
        name: element.name,
        value: element.total_tasks,
        fill: element.color,
      });
    });
    loadingUpdate(false);
    const history_length = scopes.history_snapshots.length;
    if (scopes.history_snapshots[history_length + position]) {
      var snapshots = scopes.history_snapshots[history_length + position].list;
    } else {
      snapshots = scopes.history_snapshots;
    }
    let scopesList = scopes.scopes;
    var date = 'null';
    if (snapshots.length > 0) {
      snapshots.sort((b, a) => a.date - b.date);
      date = moment(snapshots[0].date * 1000).fromNow();
    }

    if (scopesList.length > 0) {
      scopesList.sort((b, a) => a.order - b.order);
    }

    //user role
    const role =
      project.members_roles[
        project.members_emails.indexOf(getProfileData.email)
      ];

    var completedScopes = [];
    var uncompletedScopes = [];
    scopesList.forEach(scope => {
      if (
        scope.total_tasks !== scope.completed_ns + scope.completed_ms ||
        scope.total_tasks === 0
      )
        uncompletedScopes.push(scope);
      else completedScopes.push(scope);
    });

    // now lest order the completed scopes
    uncompletedScopes.sort((a, b) => a.order - b.order);
    completedScopes.sort((a, b) => a.order - b.order);

    const data = [...scopes.time_bar_data];

    for (let i = 0; i < data.length; i++) {
      if (i - history_length === position) {
        data[i].color = 'lightgreen';
      } else {
        data[i].color = data[i].color_fixed;
      }
      data[i].children = (
        <Tooltip
          key={position}
          title={'Update ' + (i + 1)}
          arrow
          placement={i - history_length === position ? 'bottom' : 'top'}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: data[i].color,
                '& .MuiTooltip-arrow': {
                  color: data[i].color,
                },
              },
            },
          }}>
          <Tooltip
            open={i - history_length === position}
            key={position}
            title={'Update ' + (i + 1)}
            arrow
            placement="bottom"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: data[i].color,
                  '& .MuiTooltip-arrow': {
                    color: data[i].color,
                  },
                },
              },
            }}>
            <div>
              <div
                className="timebar-item"
                onClick={() => setPosition(i - history_length)}
                style={{ cursor: 'pointer' }}>
                &zwnj;
              </div>
            </div>
          </Tooltip>
        </Tooltip>
      );
    }

    return (
      <div>
        <Paper
          elevation={3}
          sx={{ marginTop: '5em' }}
          style={{
            width: '100%',
            height: '100%',
            WebkitTouchCallout: 'none',
            WebkitUserSelect: 'none',
            KhtmlUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none',
          }}>
          <Grid container xs={12}>
            <Grid item xs={3}>
              <Box
                display="flex"
                justifyContent="flex-start"
                marginLeft={'1em'}
                marginTop={'1em'}>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/')}
                  startIcon={<ArrowBackIcon />}
                  size="small">
                  Go back
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" align="center" marginTop={2}>
                {project.name.charAt(0).toUpperCase() + project.name.slice(1)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box
                justifyContent="flex-start"
                display="flex"
                marginLeft={{ md: -13, sm: -3, xs: -3, lg: -17 }}>
                <PieChart width={50} height={60}>
                  <Pie
                    data={dataPie}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={16}
                  />
                </PieChart>
              </Box>
            </Grid>
            <Grid item xs={1}>
              {role === 'Project Manager' || admin ? (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginRight={'1em'}
                  marginTop={'1em'}>
                  <Button
                    variant="outlined"
                    onClick={handleClick}
                    endIcon={<MenuIcon />}
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    size="small">
                    Menu
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}>
                    <MenuItem onClick={handleMembers}>Edit members</MenuItem>
                    <MenuItem onClick={handleTags}>Edit tags</MenuItem>
                    <MenuItem onClick={handleEdit}>Edit project</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete project</MenuItem>
                  </Menu>
                </Box>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} marginTop={-1}>
              <Typography
                variant="subtitle2"
                component="div"
                align="center"
                marginBottom={'1'}>
                <b>Start date:</b> {moment(project.p_init).local().calendar()} (
                {moment(project.p_init).fromNow()})
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                component="div"
                align="center"
                marginBottom={'1'}>
                <b>Finish date:</b> {moment(project.p_due).local().calendar()} (
                {moment(project.p_due).fromNow()})
              </Typography>
            </Grid>
          </Grid>
          <Box margin={'1em'}>
            <Divider />
          </Box>
          <Grid container marginTop={'1em'}>
            <Grid item xs={4}>
              <Box
                display="flex"
                justifyContent="flex-start"
                marginLeft={'1em'}>
                {showHistory ? (
                  <Button
                    variant="outlined"
                    onClick={handleHistory}
                    startIcon={<CloseIcon />}
                    size="small">
                    Close
                  </Button>
                ) : (
                  <></>
                )}
                {(role === 'Member' || admin) && !showHistory ? (
                  preview ? (
                    <Button
                      variant="outlined"
                      onClick={handlePreview}
                      startIcon={<EditIcon />}
                      size="small">
                      Update
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={handlePreview}
                      startIcon={<EditOffIcon />}
                      size="small">
                      Cancel
                    </Button>
                  )
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" component="div" align="center">
                {position === -1 ? <b>Last updated: </b> : <b>Updated: </b>}
                {date} by {snapshots[0] ? snapshots[0].created_by : 'null'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end" marginRight={'1em'}>
                {!preview ? (
                  <Button
                    variant="outlined"
                    onClick={handleSave}
                    endIcon={<SaveIcon />}
                    size="small">
                    Save
                  </Button>
                ) : showHistory ? (
                  <>
                    <Button
                      variant="outlined"
                      disabled={position === -history_length ? true : false}
                      onClick={() => handleForwardLeft(history_length)}
                      startIcon={<KeyboardDoubleArrowLeftIcon />}
                      size="small"></Button>
                    <Button
                      variant="outlined"
                      disabled={position - 1 >= -history_length ? false : true}
                      onClick={() => handleLeft(history_length)}
                      startIcon={<NavigateBeforeIcon />}
                      size="small"></Button>
                    <Button
                      variant="outlined"
                      disabled={position + 1 < 0 ? false : true}
                      onClick={handleRight}
                      endIcon={<NavigateNextIcon />}
                      size="small"></Button>
                    <Button
                      variant="outlined"
                      disabled={position === -1 ? true : false}
                      onClick={() => handleForwardRight()}
                      startIcon={<KeyboardDoubleArrowRightIcon />}
                      size="small"></Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleHistory}
                    endIcon={<TimelineIcon />}
                    size="small">
                    History
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginRight={'1em'}>
                <Chart
                  snapshots={snapshots}
                  width={width}
                  height={height}
                  preview={preview}
                  created_by={username}
                  project_id={project.id}
                  setSnapshotDict={setSnapshotDict}
                  key={width + height + preview + snapshots + position}
                />
              </Box>
            </Grid>
            {showHistory ? (
              <Grid item xs={12}>
                <Box
                  margin={2}
                  marginLeft={'5em'}
                  marginRight={'5em'}
                  marginBottom={'2em'}>
                  <TimeProgressBar
                    hourBar={<></>}
                    props={{
                      style: { height: 10 },
                    }}
                    data={data}
                  />
                </Box>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Box margin={'1em'}>
            <Divider>TO-DO</Divider>
          </Box>
          <Grid container marginTop={'1em'}>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-start"
                marginLeft={'1em'}
                marginBottom={'1em'}>
                {role === 'Member' || admin ? (
                  <>
                    <Button
                      variant="outlined"
                      onClick={openScope}
                      startIcon={<PlaylistAddIcon />}
                      size="small">
                      Add scope
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginRight={'1em'}
                marginBottom={'1em'}>
                {role === 'Member' || admin ? (
                  <>
                    <Button
                      variant="outlined"
                      onClick={handleMove}
                      endIcon={move ? <CloseIcon /> : <ReorderIcon />}
                      size="small">
                      {move ? 'Stop moving' : 'Move scopes'}
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box marginLeft={'1em'} marginRight={'1em'} marginTop={'1em'}>
            {move ? (
              <DragabbleCard
                project={project}
                created_by={username}
                users={users}
                role={role}
                height={180}
                scopes={uncompletedScopes}
                loadingUpdate={loadingUpdate}
                getScopes={getScopes}
                updateScopes={updateScopes}
              />
            ) : (
              <Grid
                container
                justifyContent="left"
                alignItems="left"
                columns={{ xs: 4, sm: 8, md: 12 }}>
                {uncompletedScopes.map(scope => (
                  <Grid item xs={4}>
                    <Scope
                      scope={scope}
                      created_by={username}
                      users={users}
                      role={role}
                      project={project}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
          <Box margin={'1em'} paddingBottom={'1em'}>
            <Divider>DONE</Divider>
          </Box>
          <Box marginLeft={'1em'} marginRight={'1em'} marginTop={'1em'}>
            {move ? (
              <DragabbleCard
                project={project}
                created_by={username}
                users={users}
                role={role}
                height={300}
                scopes={completedScopes}
                loadingUpdate={loadingUpdate}
                getScopes={getScopes}
                updateScopes={updateScopes}
              />
            ) : (
              <Grid
                container
                justifyContent="left"
                alignItems="left"
                columns={{ xs: 4, sm: 8, md: 12 }}>
                {completedScopes.map(scope => (
                  <Grid item xs={4}>
                    <Scope
                      scope={scope}
                      project={project}
                      created_by={username}
                      users={users}
                      role={role}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Paper>
        <AddScopeDialog
          open={openScopeDialog}
          project_id={project.id}
          created_by={username}
          scope_len={scopes.length}
          closeScope={() => closeScope()}
        />
        <DeleteDialog
          open={openDeleteDialog}
          toDelete={project.id}
          toDeleteName={'project'}
          deleteFunction={deleteProject}
          getFunction={getProjects}
          loadingUpdate={loadingUpdate}
          closeDialog={setOpenDeleteDialog}
          goHome={true}
        />
        <EditProjectDialog
          open={openEditDialog}
          closeDialog={setOpenEditDialog}
          project={project}
        />
        <EditMembersDialog
          open={openMemberDialog}
          closeDialog={setOpenMemberDialog}
          project={project}
        />
        <EditTagsDialog
          open={openTagsDialog}
          closeDialog={setOpenTagsDialog}
          project={project}
        />
      </div>
    );
  } else {
    if (isLoadingScopes) {
      return (
        <>
          
        </>
      );
    } else {
      return <></>;
    }
  }
}
