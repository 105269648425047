import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, IconButton, Box } from '@mui/material';
import { IoClose } from 'react-icons/io5';

function StyledModalBox(props) {
  const { children } = props;

  return (
    <Box {...props} sx={props.style}>
      {children}
    </Box>
  );
}

export default function StyledModal(props) {
  const {
    open,
    handleClose,
    onConfirmationComplete,
    title,
    children,
    cancelationText = 'Cancelar',
    confirmationText = 'Confirmar',
    disableConfirmation = false,
    disableCancelation = false,
    secondaryText = 'Segundo',
    secondaryButton = false,
    disableTitle = false,
    disableClose = false,
    overrideStyles = {
      position: 'absolute',
      maxWidth: { xs: '90%', md: '90%' },
      borderRadius: '0.5rem',
      boxShadow: 24,
      padding: '2rem',
      maxHeight: '80vh',
      overflowY: 'auto',
      margin: '2rem',
      backgroundColor: 'white',
    },
  } = props;

  const styles = {
    iconComponent: {
      color: 'black',
    },
    IconButton: {
      position: 'absolute',
      right: '0.5rem',
      top: '0.5rem',
      color: 'text.secondary',
      ':hover': {
        backgroundColor: `{theme.palette.grey[400]}33`,
      },
    },
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}>
        <StyledModalBox style={overrideStyles}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={11}>
                  {!disableTitle && (
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    fontWeight={600}
                    textAlign={'left'}>
                    {title ? title : 'Titulo del modal'}
                  </Typography>
                  )}
                </Grid>
                <Grid item xs={1}>
                  {!disableClose && (
                  <IconButton
                    aria-label="close"
                    sx={styles.IconButton}
                    onClick={() => {
                      handleClose();
                    }}>
                    <IoClose size={20} />
                  </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} paddingY={2}>
              {children}
            </Grid>
            <Grid container justifyContent="space-between" display={'flex'}>
              <Grid item xs={4}>
                {!disableCancelation && (
                <Typography
                  onClick={() => {
                    if (!disableConfirmation) {
                      handleClose();
                    }
                  }}
                  variant="subtitle2"
                  textAlign={'left'}
                  style={{
                    cursor: disableConfirmation ? 'not-allowed' : 'pointer',
                    userSelect: 'none',
                    textAlign: 'left',
                    color: disableConfirmation ? 'grey' : 'red',
                  }}>
                  {cancelationText}
                </Typography>
                )}
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                item
                xs={4}>
                  {secondaryButton && (<Typography
                  variant="subtitle2"
                  onClick={() => {
                    if (!disableConfirmation) {
                      onConfirmationComplete();
                    }
                  }}
                  style={{
                    cursor: disableConfirmation ? 'not-allowed' : 'pointer',
                    userSelect: 'none',
                    textAlign: 'right',
                    color: disableConfirmation ? '#2196f3' : '#2196f3',
                  }}>
                  {secondaryText}
                </Typography>)}
                
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                item
                xs={4}>
                <Typography
                  variant="subtitle2"
                  onClick={() => {
                    if (!disableConfirmation) {
                      onConfirmationComplete();
                    }
                  }}
                  style={{
                    cursor: disableConfirmation ? 'not-allowed' : 'pointer',
                    userSelect: 'none',
                    textAlign: 'right',
                    color: disableConfirmation ? 'grey' : 'green',
                  }}>
                  {confirmationText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </StyledModalBox>
      </Modal>
    </div>
  );
}
