import { connect } from 'react-redux';
import { getScopes, updateScopes } from 'core/slices/scopes';
import { getUsers } from 'core/slices/users';
import { loadingUpdate } from 'core/slices/loading';
import { getProjects, deleteProject } from 'core/slices/projects';
import { postSnapshots } from 'core/slices/snapshots';
import {
  hasScopes,
  isLoadingScopes,
  getScopeList,
  hasUsers,
  getUserList,
  getProfileData,
  getProject,
  hasProfile,
  getRole,
} from 'core/selectors';
import Project from '../../../components/project/Project';

export default connect(
  state => ({
    hasScopes: hasScopes(state),
    isLoadingScopes: isLoadingScopes(state),
    scopes: getScopeList(state),
    hasUsers: hasUsers(state),
    users: getUserList(state),
    getProfileData: getProfileData(state),
    hasProfile: hasProfile(state),
    project: getProject(state),
    getRole: getRole(state),
  }),
  {
    getScopes,
    getProjects,
    postSnapshots,
    deleteProject,
    getUsers,
    loadingUpdate,
    updateScopes,
  },
)(Project);
