import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { reducer as projectsReducer } from './slices/projects.js';
import { reducer as rolesReducer } from './slices/roles.js';
import { reducer as usersReducer } from './slices/users.js';
import { reducer as tagsReducer } from './slices/tags.js';
import { reducer as scopesReducer } from './slices/scopes.js';
import { reducer as cookieReducer } from './slices/cookies.js';
import { reducer as loadingReducer } from './slices/loading.js';
import { reducer as logsReducer } from './slices/logs.js';
import { reducer as snackReducer } from './slices/snackAlert.js';
import { reducer as sessionReducer } from './slices/session.js';

export default function _createStore(services) {
  return createStore(
    combineReducers({
      projects: projectsReducer,
      roles: rolesReducer,
      users: usersReducer,
      tags: tagsReducer,
      scopes: scopesReducer,
      cookies: cookieReducer,
      loading: loadingReducer,
      logs: logsReducer,
      snack: snackReducer,
      session: sessionReducer,
      
    }),
    applyMiddleware(thunk.withExtraArgument({ services })),
  );
}
