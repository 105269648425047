import { createReducer } from './utils.js';

import { SNACK_SET } from './constants.js';

const defaultState = {
    snack: { open: false, severity: 'success', message: '' }
};

export const reducer = createReducer(defaultState, {
    [SNACK_SET]: handleSnackSet,
});

function handleSnackSet(state, { payload: { snack } }) {
    return {
        ...state,
        snack,
    };
}

export function setSnackAlert(snack) {
    return async dispatch => {
        dispatch({
            type: SNACK_SET,
            payload: { snack },
        });
    };
}
