import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Grid,
} from '@mui/material';

export default function AddScopeDialog(props) {
  const {
    project_id,
    created_by,
    postScope,
    getScopes,
    getProjects,
    closeScope,
    loadingUpdate,
    open,
  } = props;
  const [formValues, setFormValues] = useState({
    project_id,
    scope_name: '',
    description: '',
    created_by,
  });
  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };
  const handleClose = () => {
    setFormValues({
      project_id,
      scope_name: '',
      description: '',
      created_by,
    });
    closeScope();
  };

  const handleSubmit = async () => {
    handleClose();
    loadingUpdate(true);
    await postScope(formValues);
    await getScopes(project_id);
    getProjects();
    loadingUpdate(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>Add scope</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom="1em">
          To add a scope, please fill out the form below.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              margin="dense"
              id="scope-name"
              name="scope_name"
              label="Scope Name"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              defaultValue=""
              multiline
              margin="dense"
              id="description"
              name="description"
              label="Description"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          marginLeft: '1em',
          marginRight: '1em',
        }}>
        <Button onClick={handleClose}>Cancel</Button>
        {formValues.scope_name !== '' ? (
          <Button onClick={handleSubmit}>Add scope</Button>
        ) : (
          <Button disabled onClick={handleSubmit}>
            Add scope
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
